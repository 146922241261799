import React from 'react'
import { Link } from 'react-router-dom';

import Navbar from '../../component/Navbar/navbar'
import JobFooter from '../../component/Footer/jobFooter';

import {MdKeyboardArrowRight, FaArrowRight} from "../../assets/icons/icons"

export default function PagePrivacy() {
    return (
        <>
            <Navbar />

            <section className="relative table w-full py-32 lg:py-40 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid grid-cols-1 text-center mt-10">
                        <h3 className="text-3xl leading-normal font-semibold">Privacy Policy</h3>
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-flex space-x-1">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-indigo-600"><Link to="/index">Blurb</Link></li>
                        <li className="inline-block text-base mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight className="text-xl"/></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-indigo-600"><Link >Utility</Link></li>
                        <li className="inline-block text-base mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight className="text-xl"/></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-indigo-600"aria-current="page">Privacy</li>
                    </ul>
                </div>
            </section>

            <div className="relative">
                <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="md:flex justify-center">
                        <div className="md:w-3/4">
                            <div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
                                <h5 className="text-xl font-semibold mb-4">Overview :</h5>
                                <p className="text-slate-400 text-justify">Blurb Software Pvt Ltd ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you interact with our software, websites, and services.     </p>
                               
                                <h5 className="text-xl font-semibold mb-4 mt-8">1. Information We Collect</h5>
                                <p className="text-slate-400 text-justify">We may collect the following types of information</p>

                                <ul className="list-none space-x-1 text-slate-400 text-justify mt-4">
                                    <li className="flex items-center mt-2 ms-1"><FaArrowRight className="ms-2 text-[10px] text-indigo-600  align-middle me-2"/>Personal Information: Name, email address, phone number, billing information, and other identifiers you provide.</li>
                                    <li className="flex items-center mt-2"><FaArrowRight className="ms-2 text-[10px] text-indigo-600  align-middle me-2"/>Usage Data: Information about how you use our software or services, including IP addresses, browser type, device identifiers, and interaction data.</li>
                                </ul>

                                <h5 className="text-xl font-semibold mb-4 mt-8">2. How We Use Your Information</h5>
                                <p className="text-slate-400 text-justify">We use your information for the following purposes:</p>

                                <ul className="list-none space-x-1 text-slate-400 text-justify mt-4">
                                    <li className="flex items-center mt-2 ms-1"><FaArrowRight className="ms-2 text-[10px] text-indigo-600  align-middle me-2"/>To provide and maintain our software and services.</li>
                                    <li className="flex items-center mt-2"><FaArrowRight className="ms-2 text-[10px] text-indigo-600  align-middle me-2"/>To personalize your experience.</li>
                                    <li className="flex items-center mt-2"><FaArrowRight className="ms-2 text-[10px] text-indigo-600  align-middle me-2"/>To improve our products, services, and user support.</li>
                                </ul>

                                <h5 className="text-xl font-semibold mb-4 mt-8">3. How We Share Your Information</h5>
                                <p className="text-slate-400 text-justify">We may share your information in the following cases</p>

                                <ul className="list-none space-x-1 text-slate-400 text-justify mt-4">
                                    <li className="flex items-center mt-2 ms-1"><FaArrowRight className="ms-2 text-[10px] text-indigo-600  align-middle me-2"/>Service Providers: With third parties that perform services on our behalf (e.g., hosting, analytics).</li>
                                    <li className="flex items-center mt-2"><FaArrowRight className="ms-2 text-[10px] text-indigo-600  align-middle me-2"/>Legal Compliance: When required to comply with laws, regulations, or legal processes.</li>
                                </ul>

                                <h5 className="text-xl font-semibold mb-4 mt-8">4. Data Security</h5>
                                <p className="text-slate-400 text-justify">We implement appropriate technical and organizational measures to protect your information. However, no method of transmission over the Internet or method of storage is 100% secure.</p>

                           

                                <h5 className="text-xl font-semibold mb-4 mt-8">5. Cookies and Tracking Technologies</h5>
                                <p className="text-slate-400 text-justify">We may use cookies and similar technologies to collect information about your activities on our website and software. You can manage your preferences through your browser settings or our cookie management tool.</p>


                                <h5 className="text-xl font-semibold mb-4 mt-8">6. Third-Party Links</h5>
                                <p className="text-slate-400 text-justify">Our services may contain links to third-party websites. We are not responsible for the privacy practices of these websites.</p>


                                <h5 className="text-xl font-semibold mb-4 mt-8">7. Updates to This Privacy Policy</h5>
                                <p className="text-slate-400 text-justify">We may update this Privacy Policy from time to time. We will notify you of significant changes by posting the new policy on our website.      </p>


                                <h5 className="text-xl font-semibold mb-4 mt-8">8. Contact Us</h5>
                                <p className="text-slate-400 text-justify">If you have questions about this Privacy Policy, please contact us at:<a className='inline-block  font-bold duration-500 ease-in-out text-indigo-600'>mail@blurb.global </a> </p>

                           

                               
                                <div className="mt-8">
                                    <Link  className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Print</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <JobFooter/>

        </>
    )
}
