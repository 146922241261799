import React from 'react'
import logo_light from '../../assets/images/blurb-light.png';
import logo_dark from '../../assets/images/blur-dark.png';

import '../../assets/libs/@mdi/font/css/materialdesignicons.min.css';
import '../../assets/css/tailwind.css';
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'
import * as Icon from 'react-feather';

export default function Navbar(props) {
    let { navClass, navJustify } = props;
    let [isMenu, setisMenu] = useState(false);
    let [manu , setManu] = useState('');
    let location = useLocation();
    
    useEffect(()=>{
        let current = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
        setManu(current)

        function windowScroll() {
            const navbar = document.getElementById("topnav");
            if (
                document.body.scrollTop >= 50 ||
                document.documentElement.scrollTop >= 50
            ) {
                if (navbar !== null) {
                    navbar?.classList.add("nav-sticky");
                }
            } else {
                if (navbar !== null) {
                    navbar?.classList.remove("nav-sticky");
                }
            }
        }
        window.addEventListener("scroll", windowScroll);
        window.scrollTo(0, 0)
        return () => {
            window.removeEventListener('scroll', windowScroll);
        };

    },[location.pathname.substring(location.pathname.lastIndexOf('/') + 1)])
    

    const toggleMenu = () => {
        setisMenu(!isMenu);
        if (document.getElementById("navigation")) {
            const anchorArray = Array.from(document.getElementById("navigation").getElementsByTagName("a"));
            anchorArray.forEach(element => {
                element.addEventListener('click', (elem) => {
                    const target = elem.target.getAttribute("href")
                    if (target !== "") {
                        if (elem.target.nextElementSibling) {
                            var submenu = elem.target.nextElementSibling.nextElementSibling;
                            submenu.classList.toggle('open');
                        }
                    }
                })
            });
        }
    };


    return (
        <nav id="topnav" className={`defaultscroll ${navClass === "nav-light" ? '' : navClass === "nav-sticky" ?
        'bg-white dark:bg-slate-900' : ''}`}>
        <div className="container relative">
            {
                navClass === "nav-light" ?
                    <Link className="logo" to="/index">
                        <span className="inline-block dark:hidden">
                            <img src={logo_dark} className="l-dark" height="24" alt="" />
                            <img src={logo_dark} className="l-light" height="24" alt="" />
                        </span>
                        
                        <img src={logo_light} height="24" className="hidden dark:inline-block" alt="" />
                    </Link>
                    :
                    <Link className="logo" to="/index">
                        <img src={logo_light} className="inline-block dark:hidden" alt="" />
                        <img src={logo_dark} className="hidden dark:inline-block" alt="" />
                    </Link>
            }

            <div className="menu-extras">
                <div className="menu-item">
                    <Link to="#" className={`navbar-toggle ${isMenu ? 'open' : ''}`} id="isToggle" onClick={() => toggleMenu()}>
                        <div className="lines">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </Link>
                </div>
            </div>

            {
                // navClass !== 'nav-light' ? <ul className={`buy-button list-none space-x-1 mb-0`} >
                //         <li className="inline mb-0">
                //             <Link to="#" className="size-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded-full bg-indigo-600/5 hover:bg-indigo-600 border border-indigo-600/10 hover:border-indigo-600 text-indigo-600 hover:text-white"><Icon.Settings className="size-4" /></Link>
                //         </li>

                //         <li className="inline ps-1 mb-0">
                //             <Link to="https://1.envato.market/techwind-react" target="_blank" className="size-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border border-indigo-600 hover:border-indigo-700 text-white"><Icon.ShoppingCart className="size-4" /></Link>
                //         </li>
                        
                //     </ul>
                //     :
                //     <ul className="buy-button list-none space-x-1 mb-0">
                //     <li className="inline mb-0">
                //         <Link to="#">
                //             <span className="login-btn-primary"><span className="size-9 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600/5 hover:bg-indigo-600 border border-indigo-600/10 hover:border-indigo-600 text-indigo-600 hover:text-white"><Icon.Settings className="size-4" /></span></span>
                //             <span className="login-btn-light"><span className="size-9 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 border hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700"><Icon.Settings className="size-4" /></span></span>
                //         </Link>
                //     </li>
            
                //     <li className="inline ps-1 mb-0">
                //         <Link to="https://1.envato.market/techwind-react" target="_blank">
                //             <div className="login-btn-primary"><span className="size-9 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border border-indigo-600 hover:border-indigo-700 text-white"><Icon.ShoppingCart className="size-4" /></span></div>
                //             <div className="login-btn-light"><span className="size-9 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 border hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700"><Icon.ShoppingCart className="size-4" /></span></div>
                //         </Link>
                //     </li>
                // </ul>
            }
           
            <div id="navigation" style={{ display: isMenu ? 'block' : 'none' }} className='d-flex'>
                <ul className={`navigation-menu ${navClass} ${navJustify}`}>
                    {/* <li className={manu === "index" || "" ? "active" : ""}><Link to="/product" className="sub-menu-item">Product</Link></li> */}
                    
                    <li className={`${["page-aboutus", "page-services","page-team", "page-pricing","page-testimonial","user-profile","user-billing","user-payment","user-invoice","user-notification","user-setting","property-listing","property-detail","course-listing","course-detail","nft-explore","nft-auction","nft-collection","nft-creators","nft-creator-profile","nft-creator-profile-edit","nft-wallet","nft-create-item","nft-detail","food-recipe","shop-grid","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","photography-about","photography-portfolio","page-job-grid","page-job-detail","page-job-apply","page-job-post","page-job-career","page-job-candidates","page-job-candidate-detail","page-job-companies","page-Job-company-detail","forums-topic","forums-comments","helpcenter-overview","helpcenter-faqs","helpcenter-guides","helpcenter-support","blog","blog-sidebar","blog-detail","blog-standard-post","blog-slider-post","blog-gallery-post","blog-youtube-post","blog-vimeo-post","blog-audio-post","blog-blockquote-post","blog-left-sidebar-post","email-confirmation","email-password-reset","email-alert","email-invoice","auth-login","auth-signup","auth-re-password","auth-lock-screen","page-terms","page-privacy","page-comingsoon","page-maintenance","page-error","page-thankyou","contact-detail","contact-one","contact-two"].includes(manu)? "active" : ""} has-submenu parent-parent-menu-item`}>
                        <Link to="/product">Products</Link>
                        {/* <span className="menu-arrow"></span> */}
                        {/* <ul className="submenu">
                            <li className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="https://edukrypt.com/"> EduKrypt </Link>
                               
                            </li>
                            <li className={`${["user-profile", "user-billing","user-payment", "user-invoice", "user-social","user-notification","user-setting"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="https://theinteractive.in/"> The Interactive</Link> </li>
                            <li className={`${["course-listing", "course-detail"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="https://flavourpos.com/"> Flavour POS</Link></li>

                            <li className={`${["property-listing", "property-detail"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="https://visitorsquare.com/">VisitorSquare</Link> </li>
                            <li className={`${["course-listing", "course-detail"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to=""> Edumix</Link></li>
                            <li className={`${["course-listing", "course-detail"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to=""> Vsat</Link></li>
                    
                        </ul> */}
                    </li>
                    
                    <li className={`${["page-aboutus", "page-services","page-team", "page-pricing","page-testimonial","user-profile","user-billing","user-payment","user-invoice","user-notification","user-setting","property-listing","property-detail","course-listing","course-detail","nft-explore","nft-auction","nft-collection","nft-creators","nft-creator-profile","nft-creator-profile-edit","nft-wallet","nft-create-item","nft-detail","food-recipe","shop-grid","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","photography-about","photography-portfolio","page-job-grid","page-job-detail","page-job-apply","page-job-post","page-job-career","page-job-candidates","page-job-candidate-detail","page-job-companies","page-Job-company-detail","forums-topic","forums-comments","helpcenter-overview","helpcenter-faqs","helpcenter-guides","helpcenter-support","blog","blog-sidebar","blog-detail","blog-standard-post","blog-slider-post","blog-gallery-post","blog-youtube-post","blog-vimeo-post","blog-audio-post","blog-blockquote-post","blog-left-sidebar-post","email-confirmation","email-password-reset","email-alert","email-invoice","auth-login","auth-signup","auth-re-password","auth-lock-screen","page-terms","page-privacy","page-comingsoon","page-maintenance","page-error","page-thankyou","contact-detail","contact-one","contact-two"].includes(manu)? "active" : ""} has-submenu static what-we-do-menu-hover parent-parent-menu-item`}>
                        <Link to="">What We Do </Link>
                        {/* <span className=" menu-arrow"></span> */}

                        <div className='what-we-do-drop'>
                            <h3 class=" mb-2 ms-6 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold text-blue border-bottom">What We Do</h3>
                            <div className="grid md:grid-cols-3 grid-cols-1 mt-8 gap-[30px]">
                                <div  className="">
                                    <ul className=" d-block">
                                        <li>
                                            <ul className=' d-block'>
                                                <li><Link to="/application" className="sub-menu-item ms-1">Applications </Link></li>
                                                <li className={manu === "index-modern-saas" || "" ? "active" : ""}><Link to="/artificial" className="sub-menu-item">Artificial Intelligence </Link></li>
                                                <li className={manu === "index-apps" || "" ? "active" : ""}><Link to="/cloud" className="sub-menu-item">Cloud</Link></li>
                                                <li className={manu === "index-apps" || "" ? "active" : ""}><Link to="/consulting" className="sub-menu-item">Consulting</Link></li>
                                                <li className={manu === "index-apps" || "" ? "active" : ""}><Link to="/cybersecurity" className="sub-menu-item">Cybersecurity </Link></li>
                                                <li className={manu === "index-apps" || "" ? "active" : ""}><Link to="/analytics" className="sub-menu-item">Data Analytics</Link></li>
                                                <li className={manu === "index-classic-saas" || "" ? "active" : ""}><Link to="/digital_market" className="sub-menu-item">Digital Marketing</Link></li>
                                                <li className={manu === "index-classic-saas" || "" ? "active" : ""}><Link to="/e_commerce" className="sub-menu-item">E-commerce </Link></li>
                                                <li className={manu === "index-modern-saas" || "" ? "active" : ""}><Link to="/enterprise" className="sub-menu-item">Enterprise Solution </Link></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>

                                <div  className="">
                                    <ul className=" d-block">
                                        <li>
                                            <ul className=' d-block'>
                                                {/* <li className="ms-6 border-bottom ">Industries</li> */}
                                                <li className={manu === "index-apps" || "" ? "active" : ""}><Link to="/graphic_design" className="sub-menu-item ms-1">Graphic Designing</Link></li>
                                                <li className={manu === "index-apps" || "" ? "active" : ""}><Link to="/automation" className="sub-menu-item">Home Automation</Link></li>
                                                <li className={manu === "index-apps" || "" ? "active" : ""}><Link to="/home_thether" className="sub-menu-item">Home Theatre</Link></li>
                                                <li className={manu === "index-apps" || "" ? "active" : ""}><Link to="/network" className="sub-menu-item">Network Solutions</Link></li>
                                                <li className={manu === "index-classic-saas" || "" ? "active" : ""}><Link to="/classroom" className="sub-menu-item">Smart Classrooms </Link></li>
                                                <li className={manu === "index-modern-saas" || "" ? "active" : ""}><Link to="/development" className="sub-menu-item">Software Development </Link></li>
                                                <li className={manu === "index-apps" || "" ? "active" : ""}><Link to="/designing" className="sub-menu-item">UI/UX Designing</Link></li>
                                                <li className={manu === "index-apps" || "" ? "active" : ""}><Link to="/video_conferencing" className="sub-menu-item">Video Conferencing</Link></li>
                                                <li className={manu === "index-apps" || "" ? "active" : ""}><Link to="/virtual_studio" className="sub-menu-item">Virtual Studios</Link></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>

                                <div  className="">
                                   <img src='images/index/what-we-do.jpg'></img>
                                </div>
                            </div>
                        </div>
                        
                    </li>
                    <li className={`${["page-aboutus", "page-services","page-team", "page-pricing","page-testimonial","user-profile","user-billing","user-payment","user-invoice","user-notification","user-setting","property-listing","property-detail","course-listing","course-detail","nft-explore","nft-auction","nft-collection","nft-creators","nft-creator-profile","nft-creator-profile-edit","nft-wallet","nft-create-item","nft-detail","food-recipe","shop-grid","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","photography-about","photography-portfolio","page-job-grid","page-job-detail","page-job-apply","page-job-post","page-job-career","page-job-candidates","page-job-candidate-detail","page-job-companies","page-Job-company-detail","forums-topic","forums-comments","helpcenter-overview","helpcenter-faqs","helpcenter-guides","helpcenter-support","blog","blog-sidebar","blog-detail","blog-standard-post","blog-slider-post","blog-gallery-post","blog-youtube-post","blog-vimeo-post","blog-audio-post","blog-blockquote-post","blog-left-sidebar-post","email-confirmation","email-password-reset","email-alert","email-invoice","auth-login","auth-signup","auth-re-password","auth-lock-screen","page-terms","page-privacy","page-comingsoon","page-maintenance","page-error","page-thankyou","contact-detail","contact-one","contact-two"].includes(manu)? "active" : ""} has-submenu parent-parent-menu-item what-we-do-menu-hover static`}>
                        <Link to="">Industries </Link>
                        <div className='what-we-do-drop'>
                            <h3 class=" mb-2 ms-6 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold text-blue border-bottom">Industries</h3>
                            <div className="grid md:grid-cols-3 grid-cols-1 mt-8 gap-[30px]">
                                <div  className="">
                                    <ul className=" d-block">
                                        <li>
                                            <ul className=' d-block'>
                                                {/* <li className="ms-6 border-bottom ">Products</li> */}
                                                <li className={manu === "index-classic-saas" || "" ? "active" : ""}><Link to="/banking" className="sub-menu-item ms-1">Banking </Link></li>
                                                <li className={manu === "index-modern-saas" || "" ? "active" : ""}><Link to="/communication" className="sub-menu-item">Communication </Link></li>
                                                <li className={manu === "index-apps" || "" ? "active" : ""}><Link to="/cosmetology" className="sub-menu-item">Cosmetology</Link></li>
                                                <li className={manu === "index-apps" || "" ? "active" : ""}><Link to="/education" className="sub-menu-item">Education</Link></li>
                                                <li className={manu === "index-apps" || "" ? "active" : ""}><Link to="/food" className="sub-menu-item">Food </Link></li>
                                                <li className={manu === "index-apps" || "" ? "active" : ""}><Link to="/hospitality" className="sub-menu-item">Hospitality</Link></li>
                                                <li className={manu === "index-classic-saas" || "" ? "active" : ""}><Link to="/healthcare" className="sub-menu-item">Healthcare </Link></li>
                                                <li className={manu === "index-modern-saas" || "" ? "active" : ""}><Link to="/insurance" className="sub-menu-item">Insurance </Link></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>

                                <div  className="">
                                    <ul className=" d-block">
                                        <li>
                                            <ul className=' d-block'>
                                                {/* <li className="ms-6 border-bottom ">Industries</li> */}
                                                <li className={manu === "index-apps" || "" ? "active" : ""}><Link to="/logistics" className="sub-menu-item ms-1">Logistics</Link></li>
                                                <li className={manu === "index-apps" || "" ? "active" : ""}><Link to="/manufacturing" className="sub-menu-item">Manufacturing</Link></li>
                                                <li className={manu === "index-apps" || "" ? "active" : ""}><Link to="/retail" className="sub-menu-item">Retail</Link></li>
                                                <li className={manu === "index-classic-saas" || "" ? "active" : ""}><Link to="/service" className="sub-menu-item">Service </Link></li>
                                                <li className={manu === "index-modern-saas" || "" ? "active" : ""}><Link to="/software_plateform" className="sub-menu-item">Software & Digital Platform                                                </Link></li>
                                                <li className={manu === "index-apps" || "" ? "active" : ""}><Link to="/travel" className="sub-menu-item">Travel</Link></li>
                                                <li className={manu === "index-apps" || "" ? "active" : ""}><Link to="/utilities" className="sub-menu-item">Utilities</Link></li>
                                                <li className={manu === "index-apps" || "" ? "active" : ""}><Link to="/wellness" className="sub-menu-item">Wellness</Link></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>

                                <div  className="">
                                    <img src='images/index/industries.jpg'></img>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li className={`${["portfolio-modern-two", "portfolio-modern-three","portfolio-modern-four","portfolio-modern-five","portfolio-modern-six","portfolio-classic-two","portfolio-classic-three","portfolio-classic-four","portfolio-classic-five","portfolio-classic-six","portfolio-creative-two","portfolio-creative-three","portfolio-creative-four","portfolio-creative-five","portfolio-creative-six","portfolio-masonry-two","portfolio-masonry-three","portfolio-masonry-four","portfolio-masonry-five","portfolio-masonry-six","portfolio-detail-one","portfolio-detail-two","portfolio-detail-three",'portfolio-detail-four'].includes(manu)? "active" : ""} has-submenu parent-parent-menu-item what-we-do-menu-hover static`} >
                        <Link to="">Who We Are</Link>
                        <div className='what-we-do-drop'>
                            <h3 class=" mb-2 ms-6 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold text-blue border-bottom">Who We Are</h3>
                            <div className="grid md:grid-cols-3 grid-cols-1 mt-8 gap-[30px]">
                                <div  className="">
                                    <ul className=" d-block">
                                        <li>
                                            <ul className=' d-block'>
                                                {/* <li className="ms-6 border-bottom ">Products</li> */}
                                                <li className={manu === "index-classic-saas" || "" ? "active" : ""}><Link to="" className="sub-menu-item ms-1">About Us </Link></li>
                                                <li className={manu === "index-modern-saas" || "" ? "active" : ""}><Link to="" className="sub-menu-item">Our Board </Link></li>
                                                <li className={manu === "index-apps" || "" ? "active" : ""}><Link to="" className="sub-menu-item">Our People</Link></li>
                                                <li className={manu === "index-apps" || "" ? "active" : ""}><Link to="" className="sub-menu-item">Our Purpose</Link></li>
                                                <li className={manu === "index-apps" || "" ? "active" : ""}><Link to="" className="sub-menu-item">Corporate Governance </Link></li>
                                                <li className={manu === "index-apps" || "" ? "active" : ""}><Link to="" className="sub-menu-item">In Print </Link></li>
                                            </ul>
                                        </li>
                                    </ul>

                                    <ul class="list-none mt-5 space-x-1 space-y-1 who-we-are-social text-white">
                                        <li class="inline">
                                            <i class="fa fa-facebook" aria-hidden="true"></i>
                                        </li>

                                        <li class="inline">
                                            <i class="fa fa-whatsapp" aria-hidden="true"></i>
                                        </li>

                                        <li class="inline">
                                            <i class="fa fa-twitter" aria-hidden="true"></i>
                                        </li>

                                        <li class="inline">
                                            <i class="fa fa-youtube" aria-hidden="true"></i>
                                        </li>

                                        <li class="inline">
                                            <i class="fa fa-linkedin-square" aria-hidden="true"></i>
                                        </li>
                                    </ul>
                                </div>

                                <div  className="">
                                    <ul className=" d-block">
                                        <li>
                                            <ul className=' d-block'>
                                                {/* <li className="ms-6 border-bottom ">Industries</li> */}
                                                <li className={manu === "index-classic-saas" || "" ? "active" : ""}><Link to="" className="sub-menu-item ms-1">Case Study </Link></li>
                                                <li className={manu === "index-modern-saas" || "" ? "active" : ""}><Link to="" className="sub-menu-item">Events </Link></li>
                                                <li className={manu === "index-apps" || "" ? "active" : ""}><Link to="" className="sub-menu-item">Blogs</Link></li>
                                                <li className={manu === "index-apps" || "" ? "active" : ""}><Link to="" className="sub-menu-item">Our Story</Link></li>
                                                <li className={manu === "index-apps" || "" ? "active" : ""}><Link to="" className="sub-menu-item">Our Ventures</Link></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>

                                <div>
                                    <img src='images/index/who-we-are.jpg' className='radius-4'></img>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li className={`${["ui-components", "documentation","changelog"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}>
                        <Link to="/career">Career</Link>
                        {/* <span className="menu-arrow"></span> */}
                        {/* <ul className="submenu">
                            <li className={manu === "ui-components" || "" ? "active" : ""}><Link to="/page-comingsoon" className="sub-menu-item">Interactive TVs</Link></li>
                            <li className={manu === "documentation" || "" ? "active" : ""}><Link to="/page-comingsoon" className="sub-menu-item">Cameras</Link></li>
                            <li className={manu === "changelog" || "" ? "active" : ""}><Link to="/page-comingsoon" className="sub-menu-item">Microphones</Link></li>
                            <li className={manu === "changelog" || "" ? "active" : ""}><Link to="/page-comingsoon" className="sub-menu-item">Chroma</Link></li>
                            <li className={manu === "changelog" || "" ? "active" : ""}><Link to="/page-comingsoon" className="sub-menu-item">Studio Server</Link></li>
                            <li className={manu === "changelog" || "" ? "active" : ""}><Link to="/page-comingsoon" className="sub-menu-item">Writing Devices</Link></li>
                            <li className={manu === "changelog" || "" ? "active" : ""}><Link to="/page-comingsoon" className="sub-menu-item">Visualiser/ Document Camera</Link></li>
                            <li className={manu === "changelog" || "" ? "active" : ""}><Link to="/page-comingsoon" className="sub-menu-item">Digital Podiums</Link></li>
                            <li className={manu === "changelog" || "" ? "active" : ""}><Link to="/page-comingsoon" className="sub-menu-item">Studio Suite (MixKrypt)</Link></li>
                        </ul> */}
                    </li>

                    
                    <li className={`${["page-aboutus", "page-services","page-team", "page-pricing","page-testimonial","user-profile","user-billing","user-payment","user-invoice","user-notification","user-setting","property-listing","property-detail","course-listing","course-detail","nft-explore","nft-auction","nft-collection","nft-creators","nft-creator-profile","nft-creator-profile-edit","nft-wallet","nft-create-item","nft-detail","food-recipe","shop-grid","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","photography-about","photography-portfolio","page-job-grid","page-job-detail","page-job-apply","page-job-post","page-job-career","page-job-candidates","page-job-candidate-detail","page-job-companies","page-Job-company-detail","forums-topic","forums-comments","helpcenter-overview","helpcenter-faqs","helpcenter-guides","helpcenter-support","blog","blog-sidebar","blog-detail","blog-standard-post","blog-slider-post","blog-gallery-post","blog-youtube-post","blog-vimeo-post","blog-audio-post","blog-blockquote-post","blog-left-sidebar-post","email-confirmation","email-password-reset","email-alert","email-invoice","auth-login","auth-signup","auth-re-password","auth-lock-screen","page-terms","page-privacy","page-comingsoon","page-maintenance","page-error","page-thankyou","contact-detail","contact-one","contact-two"].includes(manu)? "active" : ""} has-submenu parent-parent-menu-item  globe`}>
                        <Link to=""><img src='images/index/download.svg' className='text-red'></img> </Link> </li>
            
                </ul>
            </div>
        </div >
    </nav >
    )
}
