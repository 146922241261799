import React from 'react'
import { Link } from 'react-router-dom';

import Navbar from '../../component/Navbar/navbar'
import JobFooter from '../../component/Footer/jobFooter';

import {MdKeyboardArrowRight, FaArrowRight} from "../../assets/icons/icons"

export default function PagePrivacy() {
    return (
        <>
            <Navbar />

            <section className="relative table w-full py-32 lg:py-40 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid grid-cols-1 text-center mt-10">
                        <h3 className="text-3xl leading-normal font-semibold">Terms and Conditions</h3>
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-flex space-x-1">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-indigo-600"><Link to="/index">Terms</Link></li>
                        <li className="inline-block text-base mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight className="text-xl"/></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-indigo-600"><Link >Utility</Link></li>
                        <li className="inline-block text-base mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight className="text-xl"/></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-indigo-600"aria-current="page">Terms</li>
                    </ul>
                </div>
            </section>

            <div className="relative">
                <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="md:flex justify-center">
                        <div className="md:w-3/4">
                            <div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
                                <h5 className="text-xl font-semibold mb-4">Overview :</h5>
                                <p className="text-slate-400 text-justify">Welcome to Blurb Software Pvt Ltd, a leading software development company. By using our website, services, and products, you agree to be bound by the following terms and conditions. Please read them carefully.</p>

                                <h5 className="text-xl font-semibold mb-4 mt-8">1. Acceptance of Terms</h5>
                                <p className="text-slate-400 text-justify">By accessing or using our website and services (the "Service"), you agree to comply with and be bound by these Terms and Conditions and our Privacy Policy. If you do not agree to these terms, please do not use our website or services.       </p>

                                <h5 className="text-xl font-semibold mb-4 mt-8">2. Services Provided</h5>
                                <p className="text-slate-400 text-justify">We provides custom software development, consulting, and related services ("Services"). We reserve the right to modify, suspend, or discontinue the Service at any time without notice.</p>


                                <h5 className="text-xl font-semibold mb-4 mt-8">3. User Responsibilities</h5>
                                <p className="text-slate-400 text-justify">You agree to use the Service responsibly and in compliance with all applicable laws. You are prohibited from using the Service for any unlawful activities, including but not limited to</p>

                                <ul className="list-none space-x-1 text-slate-400 text-justify mt-4">
                                    <li className="flex items-center mt-2 ms-1"><FaArrowRight className="ms-2 text-[10px] text-indigo-600  align-middle me-2"/>Violating any intellectual property rights.</li>
                                    <li className="flex items-center mt-2"><FaArrowRight className="ms-2 text-[10px] text-indigo-600  align-middle me-2"/>Distributing malware or other harmful software.</li>
                                    <li className="flex items-center mt-2"><FaArrowRight className="ms-2 text-[10px] text-indigo-600  align-middle me-2"/>Attempting to gain unauthorized access to the website, its servers, or networks.    </li>
                                </ul>

                                <h5 className="text-xl font-semibold mb-4 mt-8">4. Intellectual Property</h5>
                                <p className="text-slate-400 text-justify">All content on the website, including but not limited to text, graphics, logos, images, and software, is the property of Blurb Software Pvt Ltd and is protected by copyright laws. You may not reproduce, distribute, or create derivative works without prior written consent from us.</p>


                                <h5 className="text-xl font-semibold mb-4 mt-8">5. User Content</h5>
                                <p className="text-slate-400 text-justify">If you submit any content to the website, including feedback, suggestions, or project specifications, you grant us a non-exclusive, royalty-free, worldwide license to use, modify, and distribute this content in connection with the Service.</p>


                                <h5 className="text-xl font-semibold mb-4 mt-8">6. Privacy Policy</h5>
                                <p className="text-slate-400 text-justify">Your use of our website is governed by our Privacy Policy. By using our Services, you consent to the collection and use of your data in accordance with our Privacy Policy.</p>


                                <h5 className="text-xl font-semibold mb-4 mt-8">7. Third-Party Links</h5>
                                <p className="text-slate-400 text-justify">Our website may contain links to third-party websites. We are not responsible for the content or practices of these third-party websites. We recommend reviewing the terms and conditions and privacy policies of any linked third-party sites.</p>


                                <h5 className="text-xl font-semibold mb-4 mt-8">8. Limitation of Liability</h5>
                                <p className="text-slate-400 text-justify">To the maximum extent permitted by law, we are not liable for any direct, indirect, incidental, special, or consequential damages arising from your use or inability to use the Service, including but not limited to loss of profits, data, or business opportunities.</p>


                                <h5 className="text-xl font-semibold mb-4 mt-8">9. Indemnification</h5>
                                <p className="text-slate-400 text-justify">You agree to indemnify and hold us harmless from any claims, losses, liabilities, or expenses (including legal fees) arising from your use of the Service, violation of these terms, or infringement of any rights of third parties.</p>


                                <h5 className="text-xl font-semibold mb-4 mt-8">10. Termination</h5>
                                <p className="text-slate-400 text-justify">We reserves the right to suspend or terminate your access to the website and Services at our discretion, without notice, for any violation of these terms.</p>


                                <h5 className="text-xl font-semibold mb-4 mt-8">11. Governing Law</h5>
                                <p className="text-slate-400 text-justify">These Terms and Conditions are governed by and construed in accordance with the applicable laws. Any disputes shall be resolved in the courts of applicable laws.</p>


                                <h5 className="text-xl font-semibold mb-4 mt-8">12. Changes to Terms</h5>
                                <p className="text-slate-400 text-justify">We may update these Terms and Conditions from time to time. The latest version will be posted on this page. Your continued use of the website after such changes constitutes your acceptance of the new terms.</p>


                                <h5 className="text-xl font-semibold mb-4 mt-8">13. Contact Information</h5>
                                <p className="text-slate-400 text-justify">If you have any questions or concerns about these Terms and Conditions, please contact  us at:<a className='inline-block  font-bold duration-500 ease-in-out text-indigo-600'>mail@blurb.global </a> </p>


                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <JobFooter/>

        </>
    )
}
