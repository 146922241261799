import React from 'react'
import Navbar from '../../component/Navbar/navbar'
import { Link } from 'react-router-dom';
import {MdKeyboardArrowRight} from '../../assets/icons/icons'
import JobFooter from '../../component/Footer/jobFooter';

export default function PortfolioCreativeFive() {
    return (
        <>
            <Navbar navClass="nav-light" />
            <section className="relative table w-full py-32 lg:py-40  bg-no-repeat bg-center bg-cover">
                <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/80 to-black"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="mb-3 text-3xl leading-normal font-medium text-white">Explore All</h3>
                        <p className="text-slate-300 text-lg max-w-xl mx-auto">Showcase of Our Awesome Works in Five Columns</p>
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-flex items-center space-x-1">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/index">Techwind</Link></li>
                        <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight className="text-xl"/></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link >Portfolio</Link></li>
                        <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight className="text-xl"/></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">Creative</li>
                    </ul>
                </div>
            </section>


            <div className='container mt-24 mb-24'>
                <div className="lg:col-span-8 md:col-span-12">
                    <div className="grid grid-cols-1 pb-8">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">SAAS PRODUCTS     </h3>
                    </div>

                    <div className="grid md:grid-cols-2 grid-cols-1 gap-[30px]">
                        <div className="d-flex px-6 py-5 border-gray shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 radius-4 bg-white dark:bg-slate-900">
                            <div class="size-16 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="size-6" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M8 4C8 5.10457 7.10457 6 6 6 4.89543 6 4 5.10457 4 4 4 2.89543 4.89543 2 6 2 7.10457 2 8 2.89543 8 4ZM5 16V22H3V10C3 8.34315 4.34315 7 6 7 6.82059 7 7.56423 7.32946 8.10585 7.86333L10.4803 10.1057 12.7931 7.79289 14.2073 9.20711 10.5201 12.8943 9 11.4587V22H7V16H5ZM10 5H19V14H10V16H14.3654L17.1889 22H19.3993L16.5758 16H20C20.5523 16 21 15.5523 21 15V4C21 3.44772 20.5523 3 20 3H10V5Z"></path></svg>
                            </div>
                            <div className="content  ms-4">
                                <Link to="/page-services" className="title h5 text-lg font-medium hover:text-indigo-600">EDUKRYPT</Link>
                                <p className="text-slate-400 mt-3">Video Encryption Software with Secure LMS features. </p>
                            </div>
                        </div>

                        <div className="d-flex px-6 py-5 border-gray shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 radius-4 bg-white dark:bg-slate-900">
                            <div class="size-16 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="size-6" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M8 4C8 5.10457 7.10457 6 6 6 4.89543 6 4 5.10457 4 4 4 2.89543 4.89543 2 6 2 7.10457 2 8 2.89543 8 4ZM5 16V22H3V10C3 8.34315 4.34315 7 6 7 6.82059 7 7.56423 7.32946 8.10585 7.86333L10.4803 10.1057 12.7931 7.79289 14.2073 9.20711 10.5201 12.8943 9 11.4587V22H7V16H5ZM10 5H19V14H10V16H14.3654L17.1889 22H19.3993L16.5758 16H20C20.5523 16 21 15.5523 21 15V4C21 3.44772 20.5523 3 20 3H10V5Z"></path></svg>
                            </div>
                            <div className="content  ms-4">
                                <Link to="/page-services" className="title h5 text-lg font-medium hover:text-indigo-600">THE INTERACTIVE </Link>
                                <p className="text-slate-400 mt-3">Video Encryption Software with Secure LMS features. </p>
                            </div>
                        </div>

                        <div className="d-flex px-6 py-5 border-gray shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 radius-4 bg-white dark:bg-slate-900">
                            <div class="size-16 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="size-6" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M8 4C8 5.10457 7.10457 6 6 6 4.89543 6 4 5.10457 4 4 4 2.89543 4.89543 2 6 2 7.10457 2 8 2.89543 8 4ZM5 16V22H3V10C3 8.34315 4.34315 7 6 7 6.82059 7 7.56423 7.32946 8.10585 7.86333L10.4803 10.1057 12.7931 7.79289 14.2073 9.20711 10.5201 12.8943 9 11.4587V22H7V16H5ZM10 5H19V14H10V16H14.3654L17.1889 22H19.3993L16.5758 16H20C20.5523 16 21 15.5523 21 15V4C21 3.44772 20.5523 3 20 3H10V5Z"></path></svg>
                            </div>
                            <div className="content  ms-4">
                                <Link to="/page-services" className="title h5 text-lg font-medium hover:text-indigo-600">BUSINESS ERP </Link>
                                <p className="text-slate-400 mt-3">Video Encryption Software with Secure LMS features. </p>
                            </div>
                        </div>

                        <div className="d-flex px-6 py-5 border-gray shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 radius-4 bg-white dark:bg-slate-900">
                            <div class="size-16 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="size-6" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M8 4C8 5.10457 7.10457 6 6 6 4.89543 6 4 5.10457 4 4 4 2.89543 4.89543 2 6 2 7.10457 2 8 2.89543 8 4ZM5 16V22H3V10C3 8.34315 4.34315 7 6 7 6.82059 7 7.56423 7.32946 8.10585 7.86333L10.4803 10.1057 12.7931 7.79289 14.2073 9.20711 10.5201 12.8943 9 11.4587V22H7V16H5ZM10 5H19V14H10V16H14.3654L17.1889 22H19.3993L16.5758 16H20C20.5523 16 21 15.5523 21 15V4C21 3.44772 20.5523 3 20 3H10V5Z"></path></svg>
                            </div>
                            <div className="content  ms-4">
                                <Link to="/page-services" className="title h5 text-lg font-medium hover:text-indigo-600">APPOINTMENT BOOKING</Link>
                                <p className="text-slate-400 mt-3">Video Encryption Software with Secure LMS features. </p>
                            </div>
                        </div>
                    </div>

                    <h3 className='mb-4 md:text-3xl md:leading-normal text-2xl mt-16 mb-8 leading-normal font-semibold'>SAAS PRODUCTS</h3>

                    <div className="grid md:grid-cols-2 grid-cols-1 gap-[30px]">
                  
                        <div className="d-flex px-6 py-5 border-gray shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 radius-4 bg-white dark:bg-slate-900">
                            <div class="size-16 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="size-6" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M8 4C8 5.10457 7.10457 6 6 6 4.89543 6 4 5.10457 4 4 4 2.89543 4.89543 2 6 2 7.10457 2 8 2.89543 8 4ZM5 16V22H3V10C3 8.34315 4.34315 7 6 7 6.82059 7 7.56423 7.32946 8.10585 7.86333L10.4803 10.1057 12.7931 7.79289 14.2073 9.20711 10.5201 12.8943 9 11.4587V22H7V16H5ZM10 5H19V14H10V16H14.3654L17.1889 22H19.3993L16.5758 16H20C20.5523 16 21 15.5523 21 15V4C21 3.44772 20.5523 3 20 3H10V5Z"></path></svg>
                            </div>
                            <div className="content  ms-4">
                                <Link to="/page-services" className="title h5 text-lg font-medium hover:text-indigo-600">LEAD AI</Link>
                                <p className="text-slate-400 mt-3">Video Encryption Software with Secure LMS features. </p>
                            </div>
                        </div>

                        <div className="d-flex px-6 py-5 border-gray shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 radius-4 bg-white dark:bg-slate-900">
                            <div class="size-16 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="size-6" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M8 4C8 5.10457 7.10457 6 6 6 4.89543 6 4 5.10457 4 4 4 2.89543 4.89543 2 6 2 7.10457 2 8 2.89543 8 4ZM5 16V22H3V10C3 8.34315 4.34315 7 6 7 6.82059 7 7.56423 7.32946 8.10585 7.86333L10.4803 10.1057 12.7931 7.79289 14.2073 9.20711 10.5201 12.8943 9 11.4587V22H7V16H5ZM10 5H19V14H10V16H14.3654L17.1889 22H19.3993L16.5758 16H20C20.5523 16 21 15.5523 21 15V4C21 3.44772 20.5523 3 20 3H10V5Z"></path></svg>
                            </div>
                            <div className="content  ms-4">
                                <Link to="/page-services" className="title h5 text-lg font-medium hover:text-indigo-600">FLAVOUR</Link>
                                <p className="text-slate-400 mt-3">Video Encryption Software with Secure LMS features. </p>
                            </div>
                        </div>

                        <div className="d-flex px-6 py-5 border-gray shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 radius-4 bg-white dark:bg-slate-900">
                            <div class="size-16 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="size-6" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M8 4C8 5.10457 7.10457 6 6 6 4.89543 6 4 5.10457 4 4 4 2.89543 4.89543 2 6 2 7.10457 2 8 2.89543 8 4ZM5 16V22H3V10C3 8.34315 4.34315 7 6 7 6.82059 7 7.56423 7.32946 8.10585 7.86333L10.4803 10.1057 12.7931 7.79289 14.2073 9.20711 10.5201 12.8943 9 11.4587V22H7V16H5ZM10 5H19V14H10V16H14.3654L17.1889 22H19.3993L16.5758 16H20C20.5523 16 21 15.5523 21 15V4C21 3.44772 20.5523 3 20 3H10V5Z"></path></svg>
                            </div>
                            <div className="content  ms-4">
                                <Link to="/page-services" className="title h5 text-lg font-medium hover:text-indigo-600">TICKETLY</Link>
                                <p className="text-slate-400 mt-3">Video Encryption Software with Secure LMS features. </p>
                            </div>
                        </div>

                        <div className="d-flex px-6 py-5 border-gray shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 radius-4 bg-white dark:bg-slate-900">
                            <div class="size-16 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="size-6" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M8 4C8 5.10457 7.10457 6 6 6 4.89543 6 4 5.10457 4 4 4 2.89543 4.89543 2 6 2 7.10457 2 8 2.89543 8 4ZM5 16V22H3V10C3 8.34315 4.34315 7 6 7 6.82059 7 7.56423 7.32946 8.10585 7.86333L10.4803 10.1057 12.7931 7.79289 14.2073 9.20711 10.5201 12.8943 9 11.4587V22H7V16H5ZM10 5H19V14H10V16H14.3654L17.1889 22H19.3993L16.5758 16H20C20.5523 16 21 15.5523 21 15V4C21 3.44772 20.5523 3 20 3H10V5Z"></path></svg>
                            </div>
                            <div className="content  ms-4">
                                <Link to="/page-services" className="title h5 text-lg font-medium hover:text-indigo-600">VISITOR SQUARE</Link>
                                <p className="text-slate-400 mt-3">Video Encryption Software with Secure LMS features. </p>
                            </div>
                        </div>

                        <div className="d-flex px-6 py-5 border-gray shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 radius-4 bg-white dark:bg-slate-900">
                            <div class="size-16 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="size-6" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M8 4C8 5.10457 7.10457 6 6 6 4.89543 6 4 5.10457 4 4 4 2.89543 4.89543 2 6 2 7.10457 2 8 2.89543 8 4ZM5 16V22H3V10C3 8.34315 4.34315 7 6 7 6.82059 7 7.56423 7.32946 8.10585 7.86333L10.4803 10.1057 12.7931 7.79289 14.2073 9.20711 10.5201 12.8943 9 11.4587V22H7V16H5ZM10 5H19V14H10V16H14.3654L17.1889 22H19.3993L16.5758 16H20C20.5523 16 21 15.5523 21 15V4C21 3.44772 20.5523 3 20 3H10V5Z"></path></svg>
                            </div>
                            <div className="content  ms-4">
                                <Link to="/page-services" className="title h5 text-lg font-medium hover:text-indigo-600">BALANCE POST</Link>
                                <p className="text-slate-400 mt-3">Video Encryption Software with Secure LMS features. </p>
                            </div>
                        </div>

                        <div className="d-flex px-6 py-5 border-gray shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 radius-4 bg-white dark:bg-slate-900">
                            <div class="size-16 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="size-6" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M8 4C8 5.10457 7.10457 6 6 6 4.89543 6 4 5.10457 4 4 4 2.89543 4.89543 2 6 2 7.10457 2 8 2.89543 8 4ZM5 16V22H3V10C3 8.34315 4.34315 7 6 7 6.82059 7 7.56423 7.32946 8.10585 7.86333L10.4803 10.1057 12.7931 7.79289 14.2073 9.20711 10.5201 12.8943 9 11.4587V22H7V16H5ZM10 5H19V14H10V16H14.3654L17.1889 22H19.3993L16.5758 16H20C20.5523 16 21 15.5523 21 15V4C21 3.44772 20.5523 3 20 3H10V5Z"></path></svg>
                            </div>
                            <div className="content  ms-4">
                                <Link to="/page-services" className="title h5 text-lg font-medium hover:text-indigo-600">HOSPITAL MANAGEMENT</Link>
                                <p className="text-slate-400 mt-3">Video Encryption Software with Secure LMS features. </p>
                            </div>
                        </div>

                        <div className="d-flex px-6 py-5 border-gray shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 radius-4 bg-white dark:bg-slate-900">
                            <div class="size-16 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="size-6" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M8 4C8 5.10457 7.10457 6 6 6 4.89543 6 4 5.10457 4 4 4 2.89543 4.89543 2 6 2 7.10457 2 8 2.89543 8 4ZM5 16V22H3V10C3 8.34315 4.34315 7 6 7 6.82059 7 7.56423 7.32946 8.10585 7.86333L10.4803 10.1057 12.7931 7.79289 14.2073 9.20711 10.5201 12.8943 9 11.4587V22H7V16H5ZM10 5H19V14H10V16H14.3654L17.1889 22H19.3993L16.5758 16H20C20.5523 16 21 15.5523 21 15V4C21 3.44772 20.5523 3 20 3H10V5Z"></path></svg>
                            </div>
                            <div className="content  ms-4">
                                <Link to="/page-services" className="title h5 text-lg font-medium hover:text-indigo-600">PARKING MANAGEMENT APP</Link>
                                <p className="text-slate-400 mt-3">Video Encryption Software with Secure LMS features. </p>
                            </div>
                        </div>

                        <div className="d-flex px-6 py-5 border-gray shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 radius-4 bg-white dark:bg-slate-900">
                            <div class="size-16 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="size-6" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M8 4C8 5.10457 7.10457 6 6 6 4.89543 6 4 5.10457 4 4 4 2.89543 4.89543 2 6 2 7.10457 2 8 2.89543 8 4ZM5 16V22H3V10C3 8.34315 4.34315 7 6 7 6.82059 7 7.56423 7.32946 8.10585 7.86333L10.4803 10.1057 12.7931 7.79289 14.2073 9.20711 10.5201 12.8943 9 11.4587V22H7V16H5ZM10 5H19V14H10V16H14.3654L17.1889 22H19.3993L16.5758 16H20C20.5523 16 21 15.5523 21 15V4C21 3.44772 20.5523 3 20 3H10V5Z"></path></svg>
                            </div>
                            <div className="content  ms-4">
                                <Link to="/page-services" className="title h5 text-lg font-medium hover:text-indigo-600">DRY CLEANING STORE</Link>
                                <p className="text-slate-400 mt-3">Video Encryption Software with Secure LMS features. </p>
                            </div>
                        </div>

                        
                    </div>

                    <h3 className='mb-4 md:text-3xl md:leading-normal text-2xl mt-16 mb-8 leading-normal font-semibold'>SAAS PRODUCTS</h3>

                    <div className="grid md:grid-cols-2 grid-cols-1 gap-[30px]">

                      
                    <div className="d-flex px-6 py-5 border-gray shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 radius-4 bg-white dark:bg-slate-900">
                            <div class="size-16 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="size-6" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M8 4C8 5.10457 7.10457 6 6 6 4.89543 6 4 5.10457 4 4 4 2.89543 4.89543 2 6 2 7.10457 2 8 2.89543 8 4ZM5 16V22H3V10C3 8.34315 4.34315 7 6 7 6.82059 7 7.56423 7.32946 8.10585 7.86333L10.4803 10.1057 12.7931 7.79289 14.2073 9.20711 10.5201 12.8943 9 11.4587V22H7V16H5ZM10 5H19V14H10V16H14.3654L17.1889 22H19.3993L16.5758 16H20C20.5523 16 21 15.5523 21 15V4C21 3.44772 20.5523 3 20 3H10V5Z"></path></svg>
                            </div>
                            <div className="content  ms-4">
                                <Link to="/page-services" className="title h5 text-lg font-medium hover:text-indigo-600">HOSTEL BOOKING PLATFORM</Link>
                                <p className="text-slate-400 mt-3">Video Encryption Software with Secure LMS features. </p>
                            </div>
                        </div>

                        <div className="d-flex px-6 py-5 border-gray shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 radius-4 bg-white dark:bg-slate-900">
                            <div class="size-16 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="size-6" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M8 4C8 5.10457 7.10457 6 6 6 4.89543 6 4 5.10457 4 4 4 2.89543 4.89543 2 6 2 7.10457 2 8 2.89543 8 4ZM5 16V22H3V10C3 8.34315 4.34315 7 6 7 6.82059 7 7.56423 7.32946 8.10585 7.86333L10.4803 10.1057 12.7931 7.79289 14.2073 9.20711 10.5201 12.8943 9 11.4587V22H7V16H5ZM10 5H19V14H10V16H14.3654L17.1889 22H19.3993L16.5758 16H20C20.5523 16 21 15.5523 21 15V4C21 3.44772 20.5523 3 20 3H10V5Z"></path></svg>
                            </div>
                            <div className="content  ms-4">
                                <Link to="/page-services" className="title h5 text-lg font-medium hover:text-indigo-600">PROPERTY LISTING</Link>
                                <p className="text-slate-400 mt-3">Video Encryption Software with Secure LMS features. </p>
                            </div>
                        </div>

                        <div className="d-flex px-6 py-5 border-gray shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 radius-4 bg-white dark:bg-slate-900">
                            <div class="size-16 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="size-6" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M8 4C8 5.10457 7.10457 6 6 6 4.89543 6 4 5.10457 4 4 4 2.89543 4.89543 2 6 2 7.10457 2 8 2.89543 8 4ZM5 16V22H3V10C3 8.34315 4.34315 7 6 7 6.82059 7 7.56423 7.32946 8.10585 7.86333L10.4803 10.1057 12.7931 7.79289 14.2073 9.20711 10.5201 12.8943 9 11.4587V22H7V16H5ZM10 5H19V14H10V16H14.3654L17.1889 22H19.3993L16.5758 16H20C20.5523 16 21 15.5523 21 15V4C21 3.44772 20.5523 3 20 3H10V5Z"></path></svg>
                            </div>
                            <div className="content  ms-4">
                                <Link to="/page-services" className="title h5 text-lg font-medium hover:text-indigo-600">EMPLOYEE TRACKING SOFTWARE</Link>
                                <p className="text-slate-400 mt-3">Video Encryption Software with Secure LMS features. </p>
                            </div>
                        </div>

                        <div className="d-flex px-6 py-5 border-gray shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 radius-4 bg-white dark:bg-slate-900">
                            <div class="size-16 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="size-6" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M8 4C8 5.10457 7.10457 6 6 6 4.89543 6 4 5.10457 4 4 4 2.89543 4.89543 2 6 2 7.10457 2 8 2.89543 8 4ZM5 16V22H3V10C3 8.34315 4.34315 7 6 7 6.82059 7 7.56423 7.32946 8.10585 7.86333L10.4803 10.1057 12.7931 7.79289 14.2073 9.20711 10.5201 12.8943 9 11.4587V22H7V16H5ZM10 5H19V14H10V16H14.3654L17.1889 22H19.3993L16.5758 16H20C20.5523 16 21 15.5523 21 15V4C21 3.44772 20.5523 3 20 3H10V5Z"></path></svg>
                            </div>
                            <div className="content  ms-4">
                                <Link to="/page-services" className="title h5 text-lg font-medium hover:text-indigo-600">AMC Management Software </Link>
                                <p className="text-slate-400 mt-3">Video Encryption Software with Secure LMS features. </p>
                            </div>
                        </div>

                        <div className="d-flex px-6 py-5 border-gray shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 radius-4 bg-white dark:bg-slate-900">
                            <div class="size-16 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="size-6" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M8 4C8 5.10457 7.10457 6 6 6 4.89543 6 4 5.10457 4 4 4 2.89543 4.89543 2 6 2 7.10457 2 8 2.89543 8 4ZM5 16V22H3V10C3 8.34315 4.34315 7 6 7 6.82059 7 7.56423 7.32946 8.10585 7.86333L10.4803 10.1057 12.7931 7.79289 14.2073 9.20711 10.5201 12.8943 9 11.4587V22H7V16H5ZM10 5H19V14H10V16H14.3654L17.1889 22H19.3993L16.5758 16H20C20.5523 16 21 15.5523 21 15V4C21 3.44772 20.5523 3 20 3H10V5Z"></path></svg>
                            </div>
                            <div className="content  ms-4">
                                <Link to="/page-services" className="title h5 text-lg font-medium hover:text-indigo-600">VIDEO CONFERENCING APP</Link>
                                <p className="text-slate-400 mt-3">Video Encryption Software with Secure LMS features. </p>
                            </div>
                        </div>

                        <div className="d-flex px-6 py-5 border-gray shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 radius-4 bg-white dark:bg-slate-900">
                            <div class="size-16 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="size-6" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M8 4C8 5.10457 7.10457 6 6 6 4.89543 6 4 5.10457 4 4 4 2.89543 4.89543 2 6 2 7.10457 2 8 2.89543 8 4ZM5 16V22H3V10C3 8.34315 4.34315 7 6 7 6.82059 7 7.56423 7.32946 8.10585 7.86333L10.4803 10.1057 12.7931 7.79289 14.2073 9.20711 10.5201 12.8943 9 11.4587V22H7V16H5ZM10 5H19V14H10V16H14.3654L17.1889 22H19.3993L16.5758 16H20C20.5523 16 21 15.5523 21 15V4C21 3.44772 20.5523 3 20 3H10V5Z"></path></svg>
                            </div>
                            <div className="content  ms-4">
                                <Link to="/page-services" className="title h5 text-lg font-medium hover:text-indigo-600">EDUVSAT PRO</Link>
                                <p className="text-slate-400 mt-3">Video Encryption Software with Secure LMS features. </p>
                            </div>
                        </div>

                        <div className="d-flex px-6 py-5 border-gray shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 radius-4 bg-white dark:bg-slate-900">
                            <div class="size-16 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="size-6" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M8 4C8 5.10457 7.10457 6 6 6 4.89543 6 4 5.10457 4 4 4 2.89543 4.89543 2 6 2 7.10457 2 8 2.89543 8 4ZM5 16V22H3V10C3 8.34315 4.34315 7 6 7 6.82059 7 7.56423 7.32946 8.10585 7.86333L10.4803 10.1057 12.7931 7.79289 14.2073 9.20711 10.5201 12.8943 9 11.4587V22H7V16H5ZM10 5H19V14H10V16H14.3654L17.1889 22H19.3993L16.5758 16H20C20.5523 16 21 15.5523 21 15V4C21 3.44772 20.5523 3 20 3H10V5Z"></path></svg>
                            </div>
                            <div className="content  ms-4">
                                <Link to="/page-services" className="title h5 text-lg font-medium hover:text-indigo-600">EDUMIX PRO</Link>
                                <p className="text-slate-400 mt-3">Video Encryption Software with Secure LMS features. </p>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
                

            <JobFooter/>

        </>
    )
}
