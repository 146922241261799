import React, { useState } from 'react'
import { Link } from 'react-router-dom';

import avatar from '../../assets/images/avatar.jpg';
import ab2 from '../../assets/images/law/ab2.jpg';
import about2 from '../../assets/images/company/about2.png';
import Navbar from '../../component/Navbar/navbar'
import {FiHexagon } from '../../assets/icons/icons'

// import Footer from '../../component/Footer/footer';

import JobFooter from '../../component/Footer/jobFooter';

import {FaArrowRight} from '../../assets/icons/icons'

export default function IndexLandingFive() {
    let [setOpen] = useState(false)
    return (
        <>
            <Navbar/>

            <section className="relative py-40 lg:h-screen flex justify-center items-center bg-indigo-600/10 overflow-hidden" id="home">
                <div className="container relative">
                    <div className="grid lg:grid-cols-12 md:grid-cols-2 gap-[30px] mt-10 items-center">
                        <div className="lg:col-span-7">
                            <div className="md:text-start text-center">
                                <h1 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-6">Resources for <span className="text-indigo-600">makers &</span> <br /> <span className="text-indigo-600">Creative</span> to learn & grow</h1>
                                <p className="text-slate-400 text-lg max-w-xl">Launch your campaign and benefit from our expertise on designing and managing conversion centered Tailwind CSS v3.x html page.</p>

                                {/* <div className="subcribe-form mt-6 mb-3">
                                    <form className="relative max-w-lg">
                                        <input type="email" id="subcribe" name="email" className="pt-4 pe-40 pb-4 ps-6 w-full h-[50px] outline-none text-black dark:text-white rounded-full bg-white dark:bg-slate-900 shadow dark:shadow-gray-800" placeholder="Your Email Address :" />
                                        <button type="submit" className="py-2 px-5 inline-flex items-center font-semibold tracking-wide align-middle transition duration-500 ease-in-out text-base text-center absolute top-[2px] end-[3px] h-[46px] bg-indigo-600 hover:bg-indigo-700 border border-indigo-600 hover:border-indigo-700 text-white rounded-full">Try it for free <FaArrowRight className="ms-2 text-[10px]"/></button>
                                    </form>
                                </div> */}

                                <span className="text-slate-400 font-medium">Looking for help? <Link className="text-indigo-600">Get in touch with us</Link></span>
                            </div>
                        </div>

                        <div className="lg:col-span-5 pt-24">
                            <div className="relative">
                                <div className="relative">
                                    <img src='images/index/Cosmetology.jpg' className="lg:w-[350px] w-[200px] rounded-lg shadow dark:shadow-gray-800" alt="" />
                                   
                                </div>
                                <div className="absolute -end-5 -bottom-1">
                                    <img src='images/index/Cosmetology2.jpg' className="lg:w-[280px] w-[200px] border-8 border-white dark:border-slate-900 rounded-lg shadow dark:shadow-gray-800" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

                <div className="container relative mb-16 mt-36">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                        <div className="md:col-span-5">
                            <div class="grid grid-cols-12 gap-4 items-center">
                                <div class="col-span-7">
                                    <div class="grid grid-cols-1 gap-4">
                                        <img src="images/index/Cosmetology3.avif" class="shadow rounded-lg" alt=""/>
                                    </div>
                                </div>
                                    <div class="col-span-5">
                                        <div class="grid grid-cols-1 gap-4">
                                            <img src="images/index/Cosmetology4.avif"  class="shadow rounded-lg" alt=""/>
                                            <div class="size-28 bg-indigo-600/10 rounded-lg width100"></div>
                                        </div>
                                    </div>
                                </div>
                        </div>

                        <div className="md:col-span-7">
                            <div className="lg:ms-4">
                                <h5 className="font-medium text-lg text-indigo-600">Perfect Solution </h5>
                                <h4 className="mb-6 md:text-4xl text-3xl lg:leading-normal leading-normal font-medium">Ready to Take the Next Step?</h4>
                                <p className="text-slate-400 max-w-xl">Explore our products further and see how they can revolutionize your business. We are here to help you find the right solution.</p>
                                    <div className="mt-8">
                                            <Link to="/contact-one" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Contact us <i className="mdi mdi-arrow-right align-middle"></i></Link>
                                        </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/* <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="S_CGed6E610" onClose={() => setOpen(false)} /> */}
         
            <section className="relative" id="features">
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Business strategies and top </h3>

                        <p className="text-slate-400 max-w-xl mx-auto">Start working with Tailwind CSS that can provide everything you need to generate awareness, drive traffic, connect.</p>
                    </div>
                    <div className="grid md:grid-cols-4 grid-cols-1 mt-8 gap-[30px]">
                        <div className="group text-center">
                            <div className="size-20 bg-indigo-600/5 text-indigo-600 rounded-lg ltr:rotate-[15deg] rtl:-rotate-[15deg] flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                               <img src='images/index/flexible.png' className='width50'></img>
                            </div>
                            <div className="mt-8">
                                <Link to="" className="title h5 text-lg font-medium hover:text-indigo-600">Profitable Marketing</Link>
                                <p className="text-slate-400 mt-4">We develop digital strategies, products and services appreciated by clients.</p>
                                <div className="mt-4">
                                    <Link className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <FaArrowRight className="ms-2 text-[10px]"/></Link>
                                </div>
                            </div>
                        </div>

                        <div className="group text-center">
                            <div className="size-20 bg-indigo-600/5 text-indigo-600 rounded-lg ltr:rotate-[15deg] rtl:-rotate-[15deg] flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                               <img src='images/index/flexible.png' className='width50'></img>
                            </div>
                            <div className="mt-8">
                                <Link to="" className="title h5 text-lg font-medium hover:text-indigo-600">Profitable Marketing</Link>
                                <p className="text-slate-400 mt-4">We develop digital strategies, products and services appreciated by clients.</p>
                                <div className="mt-4">
                                    <Link className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <FaArrowRight className="ms-2 text-[10px]"/></Link>
                                </div>
                            </div>
                        </div>

                        <div className="group text-center">
                            <div className="size-20 bg-indigo-600/5 text-indigo-600 rounded-lg ltr:rotate-[15deg] rtl:-rotate-[15deg] flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                               <img src='images/index/flexible.png' className='width50'></img>
                            </div>
                            <div className="mt-8">
                                <Link to="" className="title h5 text-lg font-medium hover:text-indigo-600">Profitable Marketing</Link>
                                <p className="text-slate-400 mt-4">We develop digital strategies, products and services appreciated by clients.</p>
                                <div className="mt-4">
                                    <Link className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <FaArrowRight className="ms-2 text-[10px]"/></Link>
                                </div>
                            </div>
                        </div>

                        <div className="group text-center">
                            <div className="size-20 bg-indigo-600/5 text-indigo-600 rounded-lg ltr:rotate-[15deg] rtl:-rotate-[15deg] flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                               <img src='images/index/flexible.png' className='width50'></img>
                            </div>
                            <div className="mt-8">
                                <Link to="" className="title h5 text-lg font-medium hover:text-indigo-600">Profitable Marketing</Link>
                                <p className="text-slate-400 mt-4">We develop digital strategies, products and services appreciated by clients.</p>
                                <div className="mt-4">
                                    <Link className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <FaArrowRight className="ms-2 text-[10px]"/></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="relative overflow-hidden bg-indigo-600 rounded-md shadow dark:shadow-gray-700 mt-24">
                    <div className="grid md:grid-cols-2 items-center gap-[30px]">
                        <div className="relative">
                            <img src='images/index/cosmetology9.jpg' alt="" className='slider-image-size' />
                        </div>
                        <div>
                            <div className="text-white p-4">
                                <h4 className="md:text-3xl md:leading-normal text-2xl leading-normal font-semibold mb-2">The Future of Education: Smart Classrooms</h4>
                                <p className="text-white/70 text-lg max-w-xl text-justify">Smart classrooms are transforming education, making learning more interactive, inclusive, and engaging. By integrating modern technology into classroom spaces, schools, universities, and training centers can provide students with a dynamic learning experience. From interactive displays to seamless remote learning capabilities, smart classrooms equip educators and students with the tools they need to succeed in a connected world. Bring your classrooms into the future with a blend of innovative design and cutting-edge technology.  </p>
                            </div>
                        </div>
                    </div>
                </div>


                <section className="relative md:py-24 py-16 md:pt-0 pt-0 mt-24">
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Business strategies and top </h3>

                        <p className="text-slate-400 max-w-xl mx-auto">Start working with Tailwind CSS that can provide everything you need to generate awareness, drive traffic, connect.</p>
                    </div>
                    <div className="grid md:grid-cols-3 grid-cols-1 mt-8 gap-[30px]">
                        <div className="group text-center">
                            <img src="images/index/cosmetology6.jpg" class="shadow rounded-lg" alt=""/>
                        </div>

                        <div className="group text-center">
                            <img src="images/index/cosmetology7.avif" class="shadow rounded-lg" alt=""/>
                        </div>

                        <div className="group text-center">
                            <img src="images/index/cosmetology8.avif" class="shadow rounded-lg" alt=""/>
                        </div>

                        
                    </div>
                </div>
                </section>


                <div class="container relative md:mt-8 mt-16 pb-24">
                <div class="grid grid-cols-1 pb-8 text-center">
                    <h3 class="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">What we do ?</h3>
                    <p class="text-slate-400 max-w-xl mx-auto">Create, collaborate, and turn your ideas into incredible products with the definitive platform for digital design.</p>
                </div>

                <div class="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">

                    <div  class="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-indigo-600 dark:hover:bg-indigo-600 duration-500 rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center">
                        <div class="relative overflow-hidden text-transparent -m-3">
                            <FiHexagon class="size-24 fill-indigo-600/5 group-hover:fill-white/10 mx-auto"></FiHexagon>
                            <div class="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-indigo-600 rounded-xl group-hover:text-white duration-500 text-3xl flex align-middle justify-center items-center">
                                <img src='images/index/flexible.png' className='width15'></img>
                            </div>
                        </div>

                        <div class="mt-6">
                            <Link to="" class="text-lg font-medium group-hover:text-white duration-500">Hign Performance</Link>
                            <p class="text-slate-400 group-hover:text-white/50 duration-500 mt-3">It is a long established fact that a reader.</p>
                        </div>
                    </div>

                    <div  class="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-indigo-600 dark:hover:bg-indigo-600 duration-500 rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center">
                        <div class="relative overflow-hidden text-transparent -m-3">
                            <FiHexagon class="size-24 fill-indigo-600/5 group-hover:fill-white/10 mx-auto"></FiHexagon>
                            <div class="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-indigo-600 rounded-xl group-hover:text-white duration-500 text-3xl flex align-middle justify-center items-center">
                                <img src='images/index/flexible.png' className='width15'></img>
                            </div>
                        </div>

                        <div class="mt-6">
                            <Link to="" class="text-lg font-medium group-hover:text-white duration-500">Hign Performance</Link>
                            <p class="text-slate-400 group-hover:text-white/50 duration-500 mt-3">It is a long established fact that a reader.</p>
                        </div>
                    </div>

                    <div  class="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-indigo-600 dark:hover:bg-indigo-600 duration-500 rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center">
                        <div class="relative overflow-hidden text-transparent -m-3">
                            <FiHexagon class="size-24 fill-indigo-600/5 group-hover:fill-white/10 mx-auto"></FiHexagon>
                            <div class="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-indigo-600 rounded-xl group-hover:text-white duration-500 text-3xl flex align-middle justify-center items-center">
                                <img src='images/index/flexible.png' className='width15'></img>
                            </div>
                        </div>

                        <div class="mt-6">
                            <Link to="" class="text-lg font-medium group-hover:text-white duration-500">Hign Performance</Link>
                            <p class="text-slate-400 group-hover:text-white/50 duration-500 mt-3">It is a long established fact that a reader.</p>
                        </div>
                    </div>
                </div>
                </div>
            </section>
            <JobFooter/>

        </>
    )
}
