import React, { useState } from 'react'
import { Link } from 'react-router-dom';

import Navbar from '../../component/Navbar/navbar'
import JobFooter from '../../component/Footer/jobFooter';
import shree_logo from '../../assets/images/client/shree-logo.png';
import skype from '../../assets/images/client/skype.png';
import snapchat from '../../assets/images/client/snapchat.png';
import spotify from '../../assets/images/client/spotify.png';
import telegram from '../../assets/images/client/telegram.png';
import whatsapp from '../../assets/images/client/whatsapp.png';
import android from '../../assets/images/client/android.png';
import facebook_logo_2019 from '../../assets/images/client/facebook-logo-2019.png';
import linkedin from '../../assets/images/client/linkedin.png';
import google_logo from '../../assets/images/client/google-logo.png';

import CookieModal from '../../component/cookieModal';
import "../../../node_modules/react-modal-video/css/modal-video.css";

import {FaRegEnvelope, BiBookAlt, BsCheckCircle, MdKeyboardArrowRight, FaArrowRight} from '../../assets/icons/icons'

export default function IndexLandingThree() {
    let [isOpen, setOpen] = useState(false)
   
    return (
        <>
            <Navbar navClass="nav-light" />
            <section className='background-effect overflow-hidden'>
            <section className="relative pt-24 relative flex items-center pb-24">
               
               <div className="container relative md:mt-24 mt-36">
                   <div className="grid md:grid-cols-12 grid-cols-1 pb-8 items-end">
                       <div className="lg:col-span-8 md:col-span-6 md:text-start text-center">
                           <h3 className="md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">We create
                               world-className <br /> web design, and <br /> branding.</h3>
                       </div>

                       <div className="lg:col-span-4 md:col-span-6 md:text-end hidden md:block">
                           <Link  className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 text-slate-400 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">See  More </Link>
                       </div>
                   </div>
                   <div className="grid lg:grid-cols-12 grid-cols-1 mt-8 gap-[30px]">
                       <div className="lg:col-span-3">
                           <div className="grid lg:grid-cols-1 grid-cols-2 sticky top-20">
                               <div className="counter-box relative md:text-start text-center">
                                   <h1 className="font-bold text-3xl text-indigo-600">Educations</h1>
                               </div>

                              
                           </div>

                       </div>
                       <div className="lg:col-span-9">
                           <div className="grid md:grid-cols-2 grid-cols-1 gap-[30px]">
                               <div className="group border-b-[3px] dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 transition-all duration-500 ease-in-out hover:scale-105 relative shadow dark:shadow-gray-800 rounded-md bg-white dark:bg-slate-900 p-4 ">
                                   <div className='products-logo2 rounded-md  p-3 text-center'>
                                       <div>
                                           <img src='images/logo/logo.png' className="radius-4 edukrypt-logo" alt="" />
                                       </div>
                                   </div>

                                   <div className="content mt-7">
                                       <h4 className="title h5 text-lg font-medium hover:text-indigo-600 text-justify">Video Encryption Software  With <br/>Pilacy Free Secure LMS</h4>

                                       <div className="mt-5">
                                           <Link
                                               className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">View
                                               Now  <FaArrowRight className="ms-2 text-[10px]"/></Link>
                                       </div>
                                   </div>
                               </div>

                               <div className="group border-b-[3px] dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 transition-all duration-500 ease-in-out hover:scale-105 relative shadow dark:shadow-gray-800 rounded-md bg-white dark:bg-slate-900 p-4">
                                   <div className='products-logo2 rounded-md  p-3 text-center'>
                                       <div>
                                           <img src='images/logo/leadai.png' className="radius-4" alt="" />
                                       </div>
                                   </div>

                                   <div className="content mt-7">
                                       <h4 className="title h5 text-lg font-medium hover:text-indigo-600 text-justify">Lead Manasemont Software Specifically Designed For Educations Coaching Insititutes  With Pilacy Free Secure LMS</h4>

                                       <div className="mt-5">
                                           <Link
                                               className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">View
                                               Now   <FaArrowRight className="ms-2 text-[10px]"/></Link>
                                       </div>
                                   </div>
                               </div>

                               <div className="group border-b-[3px] dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 transition-all duration-500 ease-in-out hover:scale-105 relative shadow dark:shadow-gray-800 rounded-md bg-white dark:bg-slate-900 p-4">
                                   <div className='products-logo2 rounded-md  p-3 text-center'>
                                       <div>
                                           <img src='images/logo/edumix.png' className="radius-4" alt="" />
                                       </div>
                                   </div>

                                   <div className="content mt-7">
                                       <h4 className="title h5 text-lg font-medium hover:text-indigo-600 text-justify">Video Production Software  For Live<br/> Streming On Multiple Plateforms<br/> With Relording</h4>
                                       <div className="mt-5">
                                           <Link   className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">View
                                               Now  <FaArrowRight className="ms-2 text-[10px]"/> </Link>
                                       </div>
                                   </div>
                               </div>

                               <div className="group border-b-[3px] dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 transition-all duration-500 ease-in-out hover:scale-105 relative shadow dark:shadow-gray-800 rounded-md bg-white dark:bg-slate-900 p-4">
                                   <div className='products-logo2 rounded-md  p-3 text-center'>
                                       <div>
                                           <img src='images/logo/eduvsatpro.png' className="radius-4" alt="" />
                                       </div>
                                   </div>

                                   <div className="content mt-7">
                                       <h4 className="title h5 text-lg font-medium hover:text-indigo-600 text-align-justify">Live Prodcasting Software Dror <br/>Vsat Internet Network For Large<br/> Avoences</h4>

                                       <div className="mt-5">
                                           <Link
                                               className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">View
                                               Now  <FaArrowRight className="ms-2 text-[10px]"/></Link>
                                       </div>
                                   </div>
                               </div>

                               <div className="group border-b-[3px] dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 transition-all duration-500 ease-in-out hover:scale-105 relative shadow dark:shadow-gray-800 rounded-md bg-white dark:bg-slate-900 p-4">
                                   <div className='products-logo2 rounded-md  p-3 text-center'>
                                       <div>
                                           <img src='images/logo/interactive.png' className="radius-4" alt="" />
                                       </div>
                                   </div>

                                   <div className="content mt-7">
                                       <h4 className="title h5 text-lg font-medium hover:text-indigo-600">ERP Software For Managing Large Educations Schools Colleges / Universities</h4>

                                       <div className="mt-5">
                                           <Link
                                               className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">View
                                               Now   <FaArrowRight className="ms-2 text-[10px]"/></Link>
                                       </div>
                                   </div>
                               </div>

                               
                                     
                           </div>
                           
                       </div>
                   </div>
                   
               </div>

               <div className=" inset-0 "></div>
                <ul className="circles p-0 mb-0">
                    <li className="brand-img"><img src='images/logo/lead-favicon.png' className="" alt="" /></li>
                    <li className="brand-img"><img src='images/logo/edu-favicon.png' className="size-9" alt="" /></li>
                    <li className="brand-img"><img src='images/logo/school-favicon.png' className="size-9" alt="" /></li>
                    <li className="brand-img"><img src='images/logo/eduvsat_favicon.png' className="size-9" alt="" /></li>
                    <li className="brand-img"><img src='images/logo/edumix-favicon.png' className="size-9" alt="" /></li>
                    <li className="brand-img"><img src='images/logo/interactive-favicon.png'className="size-9" alt="" /></li>
                    <li className="brand-img"><img src='images/logo/lead-favicon.png' className="" alt="" /></li>
                    <li className="brand-img"><img src='images/logo/edu-favicon.png' className="size-9" alt="" /></li>
                    <li className="brand-img"><img src='images/logo/school-favicon.png' className="size-9" alt="" /></li>
                    <li className="brand-img"><img src='images/logo/eduvsat_favicon.png' className="size-9" alt="" /></li>
                  
                </ul>
           </section>

          
            
            </section>

           
          


            
            <JobFooter/>

            <CookieModal />
        </>
    )
}
