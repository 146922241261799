import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './assets/libs/@mdi/font/css/materialdesignicons.min.css';
import Index from './pages/index';
import Navbar from './component/Navbar/navbar';
// import Topbar from './component/Topbar';
import Industries from './pages/index/industries';
import Restaurant from './pages/index/restaurant';
import JobDetails from './pages/index/job-details';
import Android from './pages/index/android';
import JobApply from './pages/index/job-apply';
import Sales from './pages/index/sales-marketing';
import Designer from './pages/index/designer';
import Seo from './pages/index/seo';
import Career from './pages/index/career';
import Privacy from './pages/index/privacy-policy';
import Terms from './pages/index/terms.js';
import Development from './pages/index/devlopment.js';
import Classroom from './pages/index/classroom.js';
import Designing from './pages/index/ux_designing.js';
import Automation from './pages/index/automation.js';
import Graphic from './pages/index/graphic_design.js';
import Thether from './pages/index/home_thether.js';
import Conferencing from './pages/index/video_conferencing.js';
import Get_Started from './pages/index/get-started.js';
import Product from './pages/index/product';
import Network from './pages/index/network_solution.js';
import Studio from './pages/index/virtual_studio.js';
import ContactOne from './pages/contact/contactOne';
import Application from './pages/index/application';
import Consulting from './pages/index/consulting';
import Artificial from './pages/index/artificial';
import Cloud from './pages/index/cloud';
import Cybersecurity from './pages/index/cybersecutity';
import Analytics from './pages/index/analytics';
import Digital_market from './pages/index/digital_market';
import E_commerce from './pages/index/e-commerce';
import Enterprise from './pages/index/enterprise';
import Banking from './pages/index/banking';
import Communication from './pages/index/communication';
import Cosmetology from './pages/index/cosmetology';
import Education from './pages/index/education';
import Food from './pages/index/food';
import Hospitality from './pages/index/hospitality';
import Healthcare from './pages/index/healthcare';
import Insurance from './pages/index/insurance';
import Logistics from './pages/index/logistics';
import Manufacturing from './pages/index/manufacturing';
import Retail from './pages/index/retail';
import Service from './pages/index/service';
import Software_plateform from './pages/index/software_plateform';
import Travel from './pages/index/travel';
import Utilities from './pages/index/utilities';
import Wellness from './pages/index/wellness';
import Explore from './pages/index/explore';
import Switch from './component/Switch';

export default function App() {
    return (
        <BrowserRouter>
        <Switch/>
            <Routes>
                <Route path="/" element={<Index />} />
                <Route exact path="/index" element={<Index />} />
                <Route exact path="/navbar" element={<Navbar />} />
                <Route exact path="/what_we_do" element={<Restaurant />} />
                <Route exact path="/application" element={<Application />} />
                <Route exact path="/job-details" element={<JobDetails />} />
                <Route exact path="/android" element={<Android />} />
                <Route exact path="/designer" element={<Designer />} />
                <Route exact path="/seo" element={<Seo />} />
                <Route exact path="/job-apply" element={<JobApply />} />
                <Route exact path="/sales-marketing" element={<Sales />} />
                <Route exact path="/career" element={<Career />} />
                <Route exact path="/privacy-policy" element={<Privacy />} />
                <Route exact path="/terms" element={<Terms />} />
                <Route exact path="/development" element={<Development />} />
                <Route exact path="/classroom" element={<Classroom />} />
                <Route exact path="/designing" element={<Designing />} />
                <Route exact path="/consulting" element={<Consulting />} />
                <Route exact path="/automation" element={<Automation />} />
                <Route exact path="/graphic_design" element={<Graphic />} />
                <Route exact path="/home_thether" element={<Thether />} />
                <Route exact path="/network" element={<Network />} />
                <Route exact path="/video_conferencing" element={<Conferencing />} />
                <Route exact path="/virtual_studio" element={<Studio />} />
                <Route exact path="/get-started" element={<Get_Started />} />
                <Route exact path="/product" element={<Product />} />
                <Route exact path="/artificial" element={<Artificial />} />
                <Route exact path="/cloud" element={<Cloud />} />
                <Route exact path="/cybersecurity" element={<Cybersecurity />} />
                <Route exact path="/analytics" element={<Analytics />} />
                <Route exact path="/digital_market" element={<Digital_market />} />
                <Route exact path="/e_commerce" element={<E_commerce />} />
                <Route exact path="/enterprise" element={<Enterprise />} />
                <Route exact path="/banking" element={<Banking />} />
                <Route exact path="/communication" element={<Communication />} />
                <Route exact path="/cosmetology" element={<Cosmetology />} />
                <Route exact path="/education" element={<Education />} />
                <Route exact path="/food" element={<Food />} />
                <Route exact path="/hospitality" element={<Hospitality />} />
                <Route exact path="/healthcare" element={<Healthcare />} />
                <Route exact path="/insurance" element={<Insurance />} />
                <Route exact path="/logistics" element={<Logistics />} />
                <Route exact path="/manufacturing" element={<Manufacturing />} />
                <Route exact path="/retail" element={<Retail />} />
                <Route exact path="/service" element={<Service />} />
                <Route exact path="/software_plateform" element={<Software_plateform />} />
                <Route exact path="/travel" element={<Travel />} />
                <Route exact path="/utilities" element={<Utilities />} />
                <Route exact path="/wellness" element={<Wellness />} />
                <Route exact path="/industries" element={<Industries />} />
                <Route exact path="/explore" element={<Explore />} />
                <Route exact path="/contact-one" element={<ContactOne />} />
            </Routes>
        
        </BrowserRouter>
    )
}