import React, {useEffect,useState} from 'react'
import { Link } from 'react-router-dom'
import 'swiper/css';

import Navbar from '../../component/Navbar/navbar'
import JobFooter from '../../component/Footer/jobFooter';

import CookieModal from '../../component/cookieModal';
import {Autoplay, Pagination, Navigation} from 'swiper/modules';

import 'swiper/css/pagination';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
export default function IndexCrypto() {


    const [isOpen, setOpen] = useState(false)
    let [days,setDays] = useState();
    let [hours,setHours] = useState();
    let [minutes,setMinutes] = useState();
    let [seconds,setSeconds] = useState();

    let deadline = "December, 31, 2024";
  
    let getTime = () => {
      let time = Date.parse(deadline) - Date.now();
  
      setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
      setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
      setMinutes(Math.floor((time / 1000 / 60) % 60));
      setSeconds(Math.floor((time / 1000) % 60));
    };

    useEffect(() => {
    let interval = setInterval(() => getTime(deadline), 1000);
      return () => clearInterval(interval);
      }, []);
    useEffect(() => {
        const htmlTag = document.getElementsByTagName("html")[0]
        htmlTag.classList.add('dark')
    });

    let settings = {
        container: '.tiny-three-item',
        controls: false,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        speed: 400,
        gutter: 12,
        responsive: {
            992: {
                items: 3
            },
    
            767: {
                items: 2
            },
    
            320: {
                items: 1
            },
        },
    }
    return (
        <>
            <Navbar navClass="nav-light" />

                    <Swiper
                                slidesPerView={1}
                                spaceBetween={0}
                                navigation={true}
                                autoplay={{
                                    delay: 8500,
                                    disableOnInteraction: true,
                                }}
                                pagination={{
                                clickable: true,
                                }}
                                breakpoints={{
                                '@0.00': {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                '@0.75': {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                '@1.00': {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                '@1.50': {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                }}
                                modules={[Navigation,Pagination,Autoplay]}
                                className="mySwiper"
                            >
                        <SwiperSlide>
                        <section className="relative flex md:h-screen md:py-0 py-36 items-center  bg-[url('../../assets/images/coach/bg.jpg')] lg:bg-left-top bg-center bg-no-repeat bg-cover">
                            <div className="container relative z-3">
                                <div className="grid grid-cols-1 mt-10">
                                    <div className="md:text-start text-center">
                                        <span className="bg-indigo-600 inline-block text-white text-sm font-semibold p-2 rounded mb-4">Flavour Restaurant POS</span>
                                        <h1 className="font-bold text-white lg:leading-normal leading-normal text-4xl lg:text-5xl mb-6">An  All – In – One ERP                        <br/> For All Restaurant Types</h1>
                                        <p className="text-white text-lg max-w-xl text-justify">Developed to integrate, automate and simplify the restaurant operations, from inventory management to employee scheduling, order processing, and financial tracking, Flavour POS consolidates all key functions to reduce costs, improve efficiency, and maximize profitability.</p>
                                        
                                        <div className="mt-8">
                                            <Link to="" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Get Started</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        </SwiperSlide>

                        <SwiperSlide>
                        <section className="relative flex md:h-screen md:py-0 py-36 items-center  conference-banner lg:bg-left-top bg-center bg-no-repeat bg-cover">
                            <div className="container relative z-3">
                                <div className="grid grid-cols-1 mt-10">
                                    <div className="md:text-start text-center">
                                        <span className="bg-indigo-600 inline-block text-white text-sm font-semibold p-2 rounded mb-4">Conference Rooms</span>
                                        <h1 className="font-bold text-white lg:leading-normal leading-normal text-4xl lg:text-5xl mb-6">Transforming Workspaces,<br/> Empowering Collaboration </h1>
                                        <p className="text-white text-lg max-w-xl">Create dynamic and inspiring conference room with our state-of-the-art audio-visual systems, smart technology, and intuitive user interfaces.</p>
                                        
                                        <div className="mt-8">
                                            <Link to="" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Get Started</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        </SwiperSlide>

                        {/* <SwiperSlide>
                        <section className="relative flex md:h-screen md:py-0 py-36 items-center  school-banner lg:bg-left-top bg-center bg-no-repeat bg-cover">
                            <div className="container relative z-3">
                                <div className="grid grid-cols-1 mt-10">
                                    <div className="md:text-start text-center">
                                        <span className="bg-indigo-600 inline-block text-white text-sm font-semibold p-2 rounded mb-4">Conference Rooms</span>
                                        <h1 className="font-bold text-white lg:leading-normal leading-normal text-4xl lg:text-5xl mb-6">Transforming Workspaces,<br/> Empowering Collaboration </h1>
                                        <p className="text-white text-lg max-w-xl">Create dynamic and inspiring conference room with our state-of-the-art audio-visual systems, smart technology, and intuitive user interfaces.</p>
                                        
                                        <div className="mt-8">
                                            <Link to="" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Contact us <i className="mdi mdi-arrow-right align-middle"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        </SwiperSlide> */}
                    </Swiper>
            
            
            <section className='relative pt-24 '>
                <div className="container relative  ">
                        <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                            <div className="lg:col-span-5 md:col-span-6">
                                <div className="grid grid-cols-12 gap-6 items-center relative">
                                    <div className="col-span-6">
                                        <div className="grid grid-cols-1 gap-6">
                                            <img src='images/index/chat.avif' className="shadow rounded-md z-1" alt="" />
                                            <img src='images/index/online-study.avif' className="shadow rounded-md z-1" alt="" />
                                        </div>
                                    </div>

                                    <div className="col-span-6">
                                        <div className="grid grid-cols-1 gap-6">
                                            <img src='images/index/taxi.png' className="shadow rounded-md z-1" alt="" />
                                        </div>
                                    </div>
                                    <div className="about-circule overflow-hidden after:content-[''] after:absolute after:size-[512px] after:bg-indigo-600/5 after:top-0 after:-end-5 after:-z-1 after:rounded-full "></div>
                                </div>
                            </div>

                            <div className="lg:col-span-7 md:col-span-6">
                                <div className="lg:ms-5">
                                    <h3 className="mb-6 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Transforming Businesses With Mobile Apps</h3>
                                    <p className="text-slate-400 max-w-xl text-justify">In today’s digital-first world, mobile apps are essential for businesses of all sizes. Whether it’s a startup with a fresh idea or an established business looking to expand digital presence, or aiming to build a powerful e-commerce app, an engaging social platform, or a productivity tool, our Mobile Apps has touched the lives of more than 10 million people worldwide.</p>
                                </div>
                            </div>
                        </div>
                </div>
            </section>

            <div className='container-fluid'>
                <div className="grid grid-cols-1 pb-4 text-right">
                    <h3 className=" md:text-1xl text-1xl md:leading-normal leading-normal font-semibold mr-9"> Apps Developed</h3>
                </div>
            </div>
            <section className='py-1  border-gray-100 dark:border-gray-700 mb-16'>
                <div className=' relative bg-white py-3'>

                   
                        <Swiper
                        slidesPerView={6}
                        spaceBetween={10}
                        centeredSlides={false}
                        autoplay={{
                        delay: 1000,
                        disableOnInteraction: true,
                        }}

                        breakpoints={{

                            320: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            640: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                            },
                            768: {
                            slidesPerView: 4,
                            spaceBetween: 40,
                            },
                            1024: {
                            slidesPerView: 6,
                            spaceBetween: 50,
                            },
                        }}
                        pagination={{
                        clickable: false,
                        }}
                        navigation={false}
                        modules={[Autoplay]}
                        className="mySwiper"
                    >
                    <SwiperSlide>
                        <img src='images/logo/tkt-home-img.jpg' className="radius-12 clients-logo" alt="" />
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src='images/logo/bt.png' className="radius-12 clients-logo" alt="" />
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src='images/logo/pos2.png' className="radius-12 clients-logo" alt="" />
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src='images/logo/trading.jpeg' className="radius-12 clients-logo" alt="" />
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src='images/logo/logo.png' className="radius-12 clients-logo " alt="" />
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src='images/logo/interactive.png' className="radius-12 clients-logo " alt="" />
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src='images/logo/visitersqure.png' className="radius-12 clients-logo " alt="" />
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src='images/logo/flavourpos.png' className="radius-12 clients-logo " alt="" />
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src='images/logo/brighttutee2.png' className="radius-12 clients-logo" alt="" />
                    </SwiperSlide>

                    {/* <SwiperSlide>
                        <img src='images/logo/blurb.png' className="radius-12 clients-logo" alt="" />
                    </SwiperSlide> */}

                    <SwiperSlide>
                        <img src='images/logo/aa2.png' className="radius-12 clients-logo" alt="" />
                    </SwiperSlide>


                    <SwiperSlide>
                        <img src='images/logo/nikhil.jpg' className="radius-12 clients-logo" alt="" />
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src='images/logo/paras2.png' className="radius-12 clients-logo" alt="" />
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src='images/logo/mittal2.png' className="radius-12 clients-logo" alt="" />
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src='images/logo/navin logo.png' className="radius-12 clients-logo " alt="" />
                    </SwiperSlide>

                    
                    <SwiperSlide>
                        <img src='images/logo/agc.png' className="radius-12 clients-logo" alt="" />
                    </SwiperSlide>

                    
                    <SwiperSlide>
                        <img src='images/logo/mcq.webp' className="radius-12 clients-logo" alt="" />
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src='images/logo/bharatjhunjhunwala.png' className="radius-12 clients-logo relative top-2" alt="" />
                    </SwiperSlide>


                    <SwiperSlide>
                        <img src='images/logo/websankul.png' className="radius-12 clients-logo " alt="" />
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src='images/logo/ipdc.png' className="radius-12 clients-logo logo-gray" alt="" />
                    </SwiperSlide>


                    <SwiperSlide>
                        <img src='images/logo/budaniya2.png' className="radius-12 clients-logo relative top-1" alt="" />
                    </SwiperSlide>

            
                    <SwiperSlide>
                        <img src='images/logo/eco-seekho.png' className="radius-12 clients-logo" alt="" />
                    </SwiperSlide>

              

                    <SwiperSlide>
                        <img src='images/logo/aadhar-institute.png' className="radius-12 clients-logo" alt="" />
                    </SwiperSlide>


                    <SwiperSlide>
                        <img src='images/logo/img2.jfif' className="radius-12 clients-logo" alt="" />
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src='images/logo/sanskriti-ias.jpg' className="radius-12 clients-logo" alt="" />
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src='images/logo/pmg-classes2.png' className="radius-12 clients-logo" alt="" />
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src='images/logo/easysfm2.png' className="radius-12 clients-logo relative top-1" alt="" />
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src='images/logo/smart-study-online2.png' className="radius-12 clients-logo" alt="" />
                    </SwiperSlide>

                
                    <SwiperSlide>
                        <img src='images/logo/finast.webp' className="radius-12 clients-logo" alt="" />
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src='images/logo/serique.jpg' className="radius-12 clients-logo" alt="" />
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src='images/logo/logos/ab-tv.jpg' className="radius-12 clients-logo" alt="" />
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src='images/logo/logos/art2.png' className="radius-12 clients-logo" alt="" />
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src='images/logo/logos/arivupro.jpg' className="radius-12 clients-logo width70" alt="" />
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src='images/logo/logos/CA_dreamers.png' className="radius-12 clients-logo" alt="" />
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src='images/logo/logos/COC.png' className="radius-12 clients-logo" alt="" />
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src='images/logo/logos/Coursedemy.png' className="radius-12 clients-logo" alt="" />
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src='images/logo/logos/Iguruji.png' className="radius-12 clients-logo" alt="" />
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src='images/logo/logos/INtuition2.png' className="radius-12 clients-logo" alt="" />
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src='images/logo/logos/Online_Classwala.jpg' className="radius-12 clients-logo" alt="" />
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src='images/logo/logos/PARAMOUNT.png' className="radius-12 clients-logo" alt="" />
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src='images/logo/logos/Proxy_Gyan.webp' className="radius-12 clients-logo" alt="" />
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src='images/logo/logos/Sarvottam.png' className="radius-12 clients-logo" alt="" />
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src='images/logo/logos/simplifyExams.jpg' className="radius-12 clients-logo" alt="" />
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src='images/logo/logos/Uddeshya.jpg' className="radius-12 clients-logo" alt="" />
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src='images/logo/logos/vintage.png' className="radius-12 clients-logo" alt="" />
                    </SwiperSlide>

                    
                    
                    </Swiper>
                </div>
            </section>

           


            {/* <section className="relative md:py-24 py-16 bg-[url('../../assets/images/crypto/cta.jpg')] bg-center bg-cover">
                <div className="container relative">
                    <div className="grid md:grid-cols-4 sm:grid-cols-2 grid-cols-1">
                        <div className="text-center">
                            <h6 className="text-white mb-0">Founded in</h6>
                            <h2 className="mb-0 text-4xl mt-3 font-bold text-indigo-600"><CountUp className="counter-value" start={1990} end={2021} /></h2>
                        </div>
                        <div className="text-center">
                            <h6 className="text-white mb-0">Team Member</h6>
                            <h2 className="mb-0 text-4xl mt-3 font-bold text-indigo-600"><CountUp className="counter-value" start={551} end={1000} />+</h2>
                        </div>
                        <div className="text-center">
                            <h6 className="text-white mb-0">Users</h6>
                            <h2 className="mb-0 text-4xl mt-3 font-bold text-indigo-600"><CountUp className="counter-value" start={1} end={10} />M</h2>
                        </div>
                        <div className="text-center">
                            <h6 className="text-white mb-0">Insurance Cover</h6>
                            <h2 className="mb-0 text-4xl mt-3 font-bold text-indigo-600">$<CountUp className="counter-value" start={210} end={410} />M</h2>
                        </div>
                    </div>
                </div>
            </section> */}

                <div className=' relative'>
                    <div className="container relative md:mt-24 mt-16">
                    <div className="container relative md:mt-24 mt-16">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Transforming Ideas into Digital Products  </h3>

                        <p className="text-slate-400 max-w-xl mx-auto">We offer end-to-end services across the software development lifecycle.</p>
                    </div>

                    {/* <div className="grid grid-cols-1 justify-center mt-2">
                        <ul className="list-none text-center">
                            <li className="inline-block md:m-6 my-6 mx-2 text-center" >
                                <img src='images/index/analytics.png' className="shadow-md dark:shadow-gray-800 rounded-full size-20 p-4" alt="" />
                                <h4 className="mb-1 font-semibold  mt-4 text-center text-sm">EDUKRYPT</h4>
                            </li>

                            <li className="inline-block md:m-6 my-6 mx-2 text-center" >
                                <img src='images/index/analytics.png' className="shadow-md dark:shadow-gray-800 rounded-full size-20 p-4" alt="" />
                                <h4 className="mb-1 font-semibold  mt-4 text-center text-sm">THE INTERACTIVE   </h4>
                            </li>

                            <li className="inline-block md:m-6 my-6 mx-2 text-center" >
                                <img src='images/index/analytics.png' className="shadow-md dark:shadow-gray-800 rounded-full size-20 p-4" alt="" />
                                <h4 className="mb-1 font-semibold  mt-4 text-center text-sm">BUSINESS ERP               </h4>
                            </li>
                            <li className="inline-block md:m-6 my-6 mx-2 text-center" >
                                <img src='images/index/analytics.png' className="shadow-md dark:shadow-gray-800 rounded-full size-20 p-4" alt="" />
                                <h4 className="mb-1 font-semibold  mt-4 text-center text-sm">OTT APPS</h4>
                            </li>
                            <li className="inline-block md:m-6 my-6 mx-2 text-center" >
                                <img src='images/index/analytics.png' className="shadow-md dark:shadow-gray-800 rounded-full size-20 p-4" alt="" />
                                <h4 className="mb-1 font-semibold  mt-4 text-center text-sm">LEAD AI</h4>
                            </li>
                            <li className="inline-block md:m-6 my-6 mx-2 text-center" >
                                <img src='images/index/analytics.png' className="shadow-md dark:shadow-gray-800 rounded-full size-20 p-4" alt="" />
                                <h4 className="mb-1 font-semibold  mt-4 text-center text-sm">FLAVOUR</h4>
                            </li>
                            <li className="inline-block md:m-6 my-6 mx-2 text-center" >
                                <img src='images/index/analytics.png' className="shadow-md dark:shadow-gray-800 rounded-full size-20 p-4" alt="" />
                                <h4 className="mb-1 font-semibold  mt-4 text-center text-sm">TICKETLY</h4>
                            </li>
                            <li className="inline-block md:m-6 my-6 mx-2 text-center" >
                                <img src='images/index/analytics.png' className="shadow-md dark:shadow-gray-800 rounded-full size-20 p-4" alt="" />
                                <h4 className="mb-1 font-semibold  mt-4 text-center text-sm">VISITOR SQUARE</h4>
                            </li>
                            <li className="inline-block md:m-6 my-6 mx-2 text-center" >
                                <img src='images/index/analytics.png' className="shadow-md dark:shadow-gray-800 rounded-full size-20 p-4" alt="" />
                                <h4 className="mb-1 font-semibold  mt-4 text-center text-sm">BALANCE POST</h4>
                            </li>
                            <li className="inline-block md:m-6 my-6 mx-2 text-center" >
                                <img src='images/index/analytics.png' className="shadow-md dark:shadow-gray-800 rounded-full size-20 p-4" alt="" />
                                <h4 className="mb-1 font-semibold  mt-4 text-center text-sm">EDUVSAT PRO</h4>
                            </li>

                            <li className="inline-block md:m-6 my-6 mx-2 text-center" >
                                <img src='images/index/analytics.png' className="shadow-md dark:shadow-gray-800 rounded-full size-20 p-4" alt="" />
                                <h4 className="mb-1 font-semibold  mt-4 text-center text-sm">EDUMIX PRO</h4>
                            </li>

                            <li className="inline-block md:m-6 my-6 mx-2 text-center" >
                                <img src='images/index/analytics.png' className="shadow-md dark:shadow-gray-800 rounded-full size-20 p-4" alt="" />
                                <h4 className="mb-1 font-semibold  mt-4 text-center text-sm">LECTURE BAZAAR</h4>
                            </li>

                        


                        </ul>
                    </div> */}

                    {/* <div class="mt-6  d-flex justify-content-center">
                        <a class="py-2 px-5 inline-flex font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-4" href="/career"> Explore careers</a>
                    </div> */}
                </div>

                    <div className='md:col-8 mb-24'>
                        <div className="grid md:grid-cols-6 mb-16 sm:grid-cols-2 grid-cols-1 justify-center mt-2 items-center gap-[15px]">
                        <div className='products-logo rounded-md p-3 text-center'>
                            <div>
                                <img src='images/logo/edukrypt.png' className="radius-4" alt="" />
                            </div>
                            <h4 className="mb-1 font-semibold  mt-4 text-center text-sm">EDUKRYPT</h4>
                        </div>

                        <div className='products-logo rounded-md p-3 text-center'>
                            <div>
                                <img src='images/logo/interactive.png' className="radius-4" alt="" />
                            </div>
                            <h4 className="mb-1 font-semibold  mt-4 text-center text-sm">THE INTERACTIVE  </h4>
                        </div>

                        <div className='products-logo rounded-md p-3 text-center'>
                            <div>
                                <img src='images/logo/edukrypt.png' className="radius-4" alt="" />
                            </div>
                            <h4 className="mb-1 font-semibold  mt-4 text-center text-sm">BUSINESS ERP</h4>
                        </div>

                        <div className='products-logo rounded-md p-3 text-center'>
                            <div>
                                <img src='images/logo/edukrypt.png' className="radius-4" alt="" />
                            </div>
                            <h4 className="mb-1 font-semibold  mt-4 text-center text-sm">OTT APPS</h4>
                        </div>

                        <div className='products-logo rounded-md p-3 text-center'>
                            <div>
                                <img src='images/logo/leadai.png' className="radius-4" alt="" />
                            </div>
                            <h4 className="mb-1 font-semibold  mt-4 text-center text-sm">LEAD AI</h4>
                        </div>

                        <div className='products-logo rounded-md p-3 text-center'>
                            <div>
                                <img src='images/logo/flavour.png' className="radius-4" alt="" />
                            </div>
                            <h4 className="mb-1 font-semibold  mt-4 text-center text-sm">FdddLAVOUR</h4>
                        </div>

                        <div className='products-logo rounded-md p-3 text-center'>
                            <div>
                                <img src='images/logo/edukrypt.png' className="radius-4" alt="" />
                            </div>
                            <h4 className="mb-1 font-semibold  mt-4 text-center text-sm">TICKETLY</h4>
                        </div>

                        <div className='products-logo rounded-md p-3 text-center'>
                            <div>
                                <img src='images/logo/visitersqure.png' className="radius-4" alt="" />
                            </div>
                            <h4 className="mb-1 font-semibold  mt-4 text-center text-sm">VISITOR SQUARE</h4>
                        </div>

                        <div className='products-logo rounded-md p-3 text-center'>
                            <div>
                                <img src='images/logo/edukrypt.png' className="radius-4" alt="" />
                            </div>
                            <h4 className="mb-1 font-semibold  mt-4 text-center text-sm">BALANCE POST</h4>
                        </div>

                        <div className='products-logo rounded-md p-3 text-center'>
                            <div>
                                <img src='images/logo/eduvsatpro.png' className="radius-4" alt="" />
                            </div>
                            <h4 className="mb-1 font-semibold  mt-4 text-center text-sm">EDUVSAT PRO</h4>
                        </div>

                        <div className='products-logo rounded-md p-3 text-center'>
                            <div>
                                <img src='images/logo/edukrypt.png' className="radius-4" alt="" />
                            </div>
                            <h4 className="mb-1 font-semibold  mt-4 text-center text-sm">EDUMIX PRO </h4>
                        </div>

                        <div className='products-logo rounded-md p-3 text-center'>
                            <div>
                                <img src='images/logo/edukrypt.png' className="radius-4" alt="" />
                            </div>
                            <h4 className="mb-1 font-semibold  mt-4 text-center text-sm">LECTURE BAZAAR  </h4>
                        </div>
                    </div>
                    <div class="  d-flex justify-content-center">
                        <a class="py-2 px-5 inline-flex font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-4" href="/career"> Explore All</a>
                    </div>
                    </div>
                </div>

                    <Swiper
                                slidesPerView={1}
                                spaceBetween={0}
                                autoplay={{
                                    delay: 7500,
                                    disableOnInteraction: false,
                                }}
                                pagination={{
                                clickable: true,
                                }}
                                breakpoints={{
                                '@0.00': {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                '@0.75': {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                '@1.00': {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                '@1.50': {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                }}
                                modules={[Autoplay]}
                                className="mySwiper"
                            >
                        <SwiperSlide>
                                <div className="relative overflow-hidden bg-indigo-600 rounded-md shadow dark:shadow-gray-700">
                                    <div className="grid md:grid-cols-2 items-center gap-[30px]">
                                        <div className="relative">
                                            <img src='images/index/meeting.jpg' alt="" className='slider-image-size' />
                                            {/* <div className="absolute md:bottom-1/2 md:translate-y-1/2 md:-end-10 ltr:md:translate-x-0 rtl:md:translate-x-0 -bottom-10 end-1/2 ltr:translate-x-1/2 rtl:-translate-x-1/2 text-center">
                                                <Link to="#"  data-type="youtube" data-id="S_CGed6E610" className="lightbox size-20 rounded-full shadow-lg dark:shadow-gray-700 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-indigo-600 dark:text-white">
                                                    <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                                                </Link>
                                            </div> */}
                                        </div>

                                        <div>
                                            <div className="text-white p-4">
                                                <h4 className="md:text-3xl md:leading-normal text-2xl leading-normal font-semibold mb-2">Conference Room for Seamless Collaboration</h4>

                                                <p className="text-white/70 text-lg max-w-xl text-justify">Elevate your meeting spaces into efficient, tech-enabled environments where ideas can flow freely and teams can connect effectively. With our conference room design and integration services, you’ll empower your team to work smarter, innovate faster, and communicate seamlessly. Today’s conference rooms are more than just spaces for meetings. They’re collaboration hubs that connect teams, enable seamless communication, and fuel innovation. With the right design and technology, you can create an environment where teams thrive, ideas flourish, and productivity soars. </p>

                                                {/* <ul className="list-none  text-white/50 mt-4">
                                                    <li className="mb-1 flex items-center ms-0">Customized Design Solutions</li>
                                                    <li className="mb-1 flex items-center ms-0">AV and Technology Integration</li>
                                                    <li className="mb-1 flex items-center ms-0"> Quality & Innovation</li>
                                                </ul> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </SwiperSlide>

                        <SwiperSlide>
                                <div className="relative overflow-hidden bg-indigo-600 rounded-md shadow dark:shadow-gray-700">
                                    <div className="grid md:grid-cols-2 items-center gap-[30px]">
                                        <div className="relative">
                                            <img src='images/index/smart-classes.jpg' alt="" className='slider-image-size' />
                                            {/* <div className="absolute md:bottom-1/2 md:translate-y-1/2 md:-end-10 ltr:md:translate-x-0 rtl:md:translate-x-0 -bottom-10 end-1/2 ltr:translate-x-1/2 rtl:-translate-x-1/2 text-center">
                                                <Link to="#"  data-type="youtube" data-id="S_CGed6E610" className="lightbox size-20 rounded-full shadow-lg dark:shadow-gray-700 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-indigo-600 dark:text-white">
                                                    <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                                                </Link>
                                            </div> */}
                                        </div>

                                        <div>
                                            <div className="text-white p-4">
                                                <h4 className="md:text-3xl md:leading-normal text-2xl leading-normal font-semibold mb-2">The Future of Education: Smart Classrooms</h4>

                                                <p className="text-white/70 text-lg max-w-xl text-justify">Smart classrooms are transforming education, making learning more interactive, inclusive, and engaging. By integrating modern technology into classroom spaces, schools, universities, and training centers can provide students with a dynamic learning experience. From interactive displays to seamless remote learning capabilities, smart classrooms equip educators and students with the tools they need to succeed in a connected world. Bring your classrooms into the future with a blend of innovative design and cutting-edge technology.  </p>

                                                {/* <ul className="list-none  text-white/50 mt-4">
                                                    <li className="mb-1 flex items-center ms-0">Interactive Whiteboards & Displays</li>
                                                    <li className="mb-1 flex items-center ms-0">Customized Classroom Layouts</li>
                                                    <li className="mb-1 flex items-center ms-0">Seamless Connectivity</li>
                                                </ul> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </SwiperSlide>

                        <SwiperSlide>
                                <div className="relative overflow-hidden bg-indigo-600 rounded-md shadow dark:shadow-gray-700">
                                    <div className="grid md:grid-cols-2 items-center gap-[30px]">
                                        <div className="relative">
                                            <img src='images/index/studio.jpg' alt="" className='slider-image-size' />
                                            {/* <div className="absolute md:bottom-1/2 md:translate-y-1/2 md:-end-10 ltr:md:translate-x-0 rtl:md:translate-x-0 -bottom-10 end-1/2 ltr:translate-x-1/2 rtl:-translate-x-1/2 text-center">
                                                <Link to="#"  data-type="youtube" data-id="S_CGed6E610" className="lightbox size-20 rounded-full shadow-lg dark:shadow-gray-700 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-indigo-600 dark:text-white">
                                                    <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                                                </Link>
                                            </div> */}
                                        </div>

                                        <div>
                                            <div className="text-white p-4">
                                                <h4 className="md:text-3xl md:leading-normal text-2xl leading-normal font-semibold mb-2">Create Exceptional Studios with <br/>Innovative Design</h4>

                                                <p className="text-white/70 text-lg max-w-xl text-justify">Whether you're creating a music studio, educational studio, video production suite, or podcasting hub, we integrate the latest technology to ensure high-quality output and smooth production processes. We use innovative techniques to create the perfect atmosphere, from studio sets to broadcasting.</p>

                                                <ul className="list-none  text-white/50 mt-4">
                                                    <li className="mb-1 flex items-center ms-0">Custom Studio Layout </li>
                                                    <li className="mb-1 flex items-center ms-0">Advanced Technology Integration</li>
                                                    <li className="mb-1 flex items-center ms-0">Studio Control Rooms</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </SwiperSlide>

                        <SwiperSlide>
                                <div className="relative overflow-hidden bg-indigo-600 rounded-md shadow dark:shadow-gray-700">
                                    <div className="grid md:grid-cols-2 items-center gap-[30px]">
                                        <div className="relative">
                                            <img src='images/index/cloud.jpg' alt="" className='slider-image-size'/>
                                            {/* <div className="absolute md:bottom-1/2 md:translate-y-1/2 md:-end-10 ltr:md:translate-x-0 rtl:md:translate-x-0 -bottom-10 end-1/2 ltr:translate-x-1/2 rtl:-translate-x-1/2 text-center">
                                                <Link to="#"  data-type="youtube" data-id="S_CGed6E610" className="lightbox size-20 rounded-full shadow-lg dark:shadow-gray-700 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-indigo-600 dark:text-white">
                                                    <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                                                </Link>
                                            </div> */}
                                        </div>

                                        <div>
                                            <div className="text-white p-4">
                                                <h4 className="md:text-3xl md:leading-normal text-2xl leading-normal font-semibold mb-2">Unlock the Power of Cloud with Secure Infrastructure</h4>

                                                <p className="text-white/70 text-lg max-w-xl text-justify">We specialize in providing cutting-edge cloud infrastructure solutions that empower businesses to scale, innovate, and stay competitive. Whether you're migrating to the cloud or optimizing your existing cloud setup, we offer tailored solutions through VPS server, video server and live server for your organization's needs.</p>

                                                <ul className="list-none  text-white/50 mt-4">
                                                    <li className="mb-1 flex items-center ms-0">Cloud Migration Strategy</li>
                                                    <li className="mb-1 flex items-center ms-0">Cloud Deployment & Integration</li>
                                                    <li className="mb-1 flex items-center ms-0">Cloud Monitoring & Management</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </SwiperSlide>
                    </Swiper>
                  
                </div>
            



            <section className='relative pb-12 mt-24'>
                <div className='container'>
                    <div className="grid md:grid-cols-12 grid-cols-1 pb-7 items-center text-center">
                        <div className="md:col-span-12">
                            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Tailored Solutions for Every Industry</h3>
                            <p className="text-slate-400 text-center">We are specialize in delivering tailored software solutions across diverse industries.</p>
                        </div>
                    </div>
                    <Swiper
                                slidesPerView={1}
                                spaceBetween={0}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                pagination={{
                                clickable: true,
                                }}
                                breakpoints={{
                                '@0.00': {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                '@0.75': {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                '@1.00': {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                '@1.50': {
                                    slidesPerView: 3,
                                    spaceBetween: 10,
                                },
                                }}
                                modules={[Autoplay]}
                                className="mySwiper"
                            >
                        <SwiperSlide>
                        <div className="sm:w-1/1 picture-item p-4 rounded-md">
                            <div className="">
                                <div className="relative">
                                    <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-emerald-600/5 dark:bg-emerald-600/30">
                                        <img src='images/index/banking.jpg' className="rounded-t-md shadow" alt="" />

                                    </div>
                                </div>

                                <div className="pt-4 px-3">
                                    <h5 className="mb-1 font-semibold text-xl"><Link to="#" className="hover:text-emerald-600 transition-all duration-500 ease-in-out">Banking</Link></h5>
                                    {/* <span className="text-slate-400">At Blurb Software, we specialize in delivering innovative software solutions </span> */}
                                </div>
                            </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="sm:w-1/1 picture-item p-4 rounded-md">
                            <div className="">
                                <div className="relative">
                                    <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-emerald-600/5 dark:bg-emerald-600/30">
                                        <img src='images/index/communication2.avif' className="rounded-t-md shadow" alt="" />

                                    </div>
                                </div>

                                <div className="pt-4 px-3">
                                    <h5 className="mb-1 font-semibold text-xl"><Link to="#" className="hover:text-emerald-600 transition-all duration-500 ease-in-out">Communication</Link></h5>
                                    {/* <span className="text-slate-400">At Blurb Software, we specialize in delivering innovative software solutions </span> */}
                                </div>
                            </div>
                        </div>
                        </SwiperSlide>

                        <SwiperSlide>
                        <div className="sm:w-1/1 picture-item p-4 rounded-md">
                            <div className="">
                                <div className="relative">
                                    <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-emerald-600/5 dark:bg-emerald-600/30">
                                        <img src='images/index/Cosmetology2.jpg' className="rounded-t-md shadow" alt="" />

                                    </div>
                                </div>

                                <div className="pt-4 px-3">
                                    <h5 className="mb-1 font-semibold text-xl"><Link to="#" className="hover:text-emerald-600 transition-all duration-500 ease-in-out">Cosmetology</Link></h5>
                                    {/* <span className="text-slate-400">At Blurb Software, we specialize in delivering innovative software solutions </span> */}
                                </div>
                            </div>
                        </div>
                        </SwiperSlide>

                        <SwiperSlide>
                        <div className="sm:w-1/1 picture-item p-4 rounded-md">
                            <div className="">
                                <div className="relative">
                                    <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-emerald-600/5 dark:bg-emerald-600/30">
                                        <img src='images/index/education.jpg' className="rounded-t-md shadow" alt="" />

                                    </div>
                                </div>

                                <div className="pt-4 px-3">
                                    <h5 className="mb-1 font-semibold text-xl"><Link to="#" className="hover:text-emerald-600 transition-all duration-500 ease-in-out">Education</Link></h5>
                                    {/* <span className="text-slate-400">At Blurb Software, we specialize in delivering innovative software solutions </span> */}
                                </div>
                            </div>
                        </div>
                        </SwiperSlide>

                        <SwiperSlide>
                        <div className="sm:w-1/1 picture-item p-4 rounded-md">
                            <div className="">
                                <div className="relative">
                                    <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-emerald-600/5 dark:bg-emerald-600/30">
                                        <img src='images/index/food.jpg' className="rounded-t-md shadow" alt="" />

                                    </div>
                                </div>

                                <div className="pt-4 px-3">
                                    <h5 className="mb-1 font-semibold text-xl"><Link to="#" className="hover:text-emerald-600 transition-all duration-500 ease-in-out">Food</Link></h5>
                                    {/* <span className="text-slate-400">At Blurb Software, we specialize in delivering innovative software solutions </span> */}
                                </div>
                            </div>
                        </div>
                        </SwiperSlide>

                        <SwiperSlide>
                        <div className="sm:w-1/1 picture-item p-4 rounded-md">
                            <div className="">
                                <div className="relative">
                                    <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-emerald-600/5 dark:bg-emerald-600/30">
                                        <img src='images/index/Hospitality2.jpg' className="rounded-t-md shadow" alt="" />

                                    </div>
                                </div>

                                <div className="pt-4 px-3">
                                    <h5 className="mb-1 font-semibold text-xl"><Link to="#" className="hover:text-emerald-600 transition-all duration-500 ease-in-out">Hospitality</Link></h5>
                                    {/* <span className="text-slate-400">At Blurb Software, we specialize in delivering innovative software solutions </span> */}
                                </div>
                            </div>
                        </div>
                        </SwiperSlide>

                        <SwiperSlide>
                        <div className="sm:w-1/1 picture-item p-4 rounded-md">
                            <div className="">
                                <div className="relative">
                                    <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-emerald-600/5 dark:bg-emerald-600/30">
                                        <img src='images/index/Healthcare2.jpg' className="rounded-t-md shadow" alt="" />

                                    </div>
                                </div>

                                <div className="pt-4 px-3">
                                    <h5 className="mb-1 font-semibold text-xl"><Link to="#" className="hover:text-emerald-600 transition-all duration-500 ease-in-out">Healthcare</Link></h5>
                                    {/* <span className="text-slate-400">At Blurb Software, we specialize in delivering innovative software solutions </span> */}
                                </div>
                            </div>
                        </div>
                        </SwiperSlide>

                        <SwiperSlide>
                        <div className="sm:w-1/1 picture-item p-4 rounded-md">
                            <div className="">
                                <div className="relative">
                                    <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-emerald-600/5 dark:bg-emerald-600/30">
                                        <img src='images/index/Insurance.jpg' className="rounded-t-md shadow" alt="" />

                                    </div>
                                </div>

                                <div className="pt-4 px-3">
                                    <h5 className="mb-1 font-semibold text-xl"><Link to="#" className="hover:text-emerald-600 transition-all duration-500 ease-in-out">Insurance</Link></h5>
                                    {/* <span className="text-slate-400">At Blurb Software, we specialize in delivering innovative software solutions </span> */}
                                </div>
                            </div>
                        </div>
                        </SwiperSlide>


                        <SwiperSlide>
                        <div className="sm:w-1/1 picture-item p-4 rounded-md">
                            <div className="">
                                <div className="relative">
                                    <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-emerald-600/5 dark:bg-emerald-600/30">
                                        <img src='images/index/Logistics.avif' className="rounded-t-md shadow" alt="" />

                                    </div>
                                </div>

                                <div className="pt-4 px-3">
                                    <h5 className="mb-1 font-semibold text-xl"><Link to="#" className="hover:text-emerald-600 transition-all duration-500 ease-in-out">Logistics</Link></h5>
                                    {/* <span className="text-slate-400">At Blurb Software, we specialize in delivering innovative software solutions </span> */}
                                </div>
                            </div>
                        </div>
                        </SwiperSlide>

                        <SwiperSlide>
                        <div className="sm:w-1/1 picture-item p-4 rounded-md">
                            <div className="">
                                <div className="relative">
                                    <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-emerald-600/5 dark:bg-emerald-600/30">
                                        <img src='images/index/Manufacturing.avif' className="rounded-t-md shadow" alt="" />

                                    </div>
                                </div>

                                <div className="pt-4 px-3">
                                    <h5 className="mb-1 font-semibold text-xl"><Link to="#" className="hover:text-emerald-600 transition-all duration-500 ease-in-out">Manufacturing</Link></h5>
                                    {/* <span className="text-slate-400">At Blurb Software, we specialize in delivering innovative software solutions </span> */}
                                </div>
                            </div>
                        </div>
                        </SwiperSlide>

                        <SwiperSlide>
                        <div className="sm:w-1/1 picture-item p-4 rounded-md">
                            <div className="">
                                <div className="relative">
                                    <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-emerald-600/5 dark:bg-emerald-600/30">
                                        <img src='images/index/Retail2.jpg' className="rounded-t-md shadow" alt="" />

                                    </div>
                                </div>

                                <div className="pt-4 px-3">
                                    <h5 className="mb-1 font-semibold text-xl"><Link to="#" className="hover:text-emerald-600 transition-all duration-500 ease-in-out">Retail</Link></h5>
                                    {/* <span className="text-slate-400">At Blurb Software, we specialize in delivering innovative software solutions </span> */}
                                </div>
                            </div>
                        </div>
                        </SwiperSlide>

                        <SwiperSlide>
                        <div className="sm:w-1/1 picture-item p-4 rounded-md">
                            <div className="">
                                <div className="relative">
                                    <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-emerald-600/5 dark:bg-emerald-600/30">
                                        <img src='images/index/Service2.jpg' className="rounded-t-md shadow" alt="" />

                                    </div>
                                </div>

                                <div className="pt-4 px-3">
                                    <h5 className="mb-1 font-semibold text-xl"><Link to="#" className="hover:text-emerald-600 transition-all duration-500 ease-in-out">Service</Link></h5>
                                    {/* <span className="text-slate-400">At Blurb Software, we specialize in delivering innovative software solutions </span> */}
                                </div>
                            </div>
                        </div>
                        </SwiperSlide>

                        <SwiperSlide>
                        <div className="sm:w-1/1 picture-item p-4 rounded-md">
                            <div className="">
                                <div className="relative">
                                    <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-emerald-600/5 dark:bg-emerald-600/30">
                                        <img src='images/index/software.avif' className="rounded-t-md shadow" alt="" />

                                    </div>
                                </div>

                                <div className="pt-4 px-3">
                                    <h5 className="mb-1 font-semibold text-xl"><Link to="#" className="hover:text-emerald-600 transition-all duration-500 ease-in-out">Software & Digital Platform</Link></h5>
                                    {/* <span className="text-slate-400">At Blurb Software, we specialize in delivering innovative software solutions </span> */}
                                </div>
                            </div>
                        </div>
                        </SwiperSlide>
        
                    </Swiper>
                </div>
            </section>


                  
             {/* <section className='relative py-16 bg-indigo-600/5'>
                    <div className="container relative mt-4">
                        <div className="grid grid-cols-1  text-center">
                            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Client Success Stories</h3>
                            <p className="text-white/50 text-slate-400  mx-auto">We pride ourselves on delivering exceptional results and building lasting partnerships.</p>
                        </div>
                    </div>
                    <div className='container '>
                        <div class="grid lg:grid-cols-1 md:grid-cols-2 grid-cols-2  gap-[30px]">
                            <div className="cursor-e-resize">
                                <div className="content relative  m-2 p-6  before:content-[''] before:absolute before:start-1/2 before:-bottom-[4px] before:box-border before:border-8 before:rotate-[45deg] before:border-t-transparent before:border-e-white dark:before:border-e-slate-900 before:border-b-white dark:before:border-b-slate-900 before:border-s-transparent before:shadow-testi dark:before:shadow-gray-700 before:origin-top-left">
                                    <i className="mdi mdi-format-quote-open mdi-48px text-indigo-600"></i>
                                    <p className=" text-white/50 text-slate-400 text-justify">I've had the pleasure of working with EduKrypt Corporation Private Limited for the past years, and I couldn't be happier with the results. As owner of Power of Stocks, I was in desperate need of a software solution to streamline our course delivery and LMS with high standard of security. EduKrypt Corporation Private Limited came highly recommended, and they certainly lived up to their reputation.
                                        From the initial consultation to the final implementation, the team at EduKrypt Corporation Private Limited demonstrated a high level of professionalism and expertise. They took the time to understand our unique business needs and challenges, and they customized a software solution that exceeded our expectations.
                                        One of the standout features of working with EduKrypt Corporation Private Limited was their commitment to clear communication. They kept us informed every step of the way, ensuring that we were always in the loop regarding project progress. Any questions or concerns we had were addressed promptly, and they were always available to provide support and guidance.
                                        The software they developed for us has transformed our business. It has streamlined our lecture delivery, course management and improved our customer relationship management, and enhanced our overall productivity. Not only has it saved us time, but it has also increased our profitability. The return on investment has been outstanding.
                                        Furthermore, the post-implementation support provided by EduKrypt Corporation Private Limited has been exceptional. They continue to be responsive to our needs and have shown a commitment to helping us evolve and grow with our software.
                                        In summary, I wholeheartedly recommend EduKrypt Corporation Private Limited to any business in need of a Education or Business Apps. Their expertise, dedication, and commitment to customer satisfaction are truly commendable. Thanks to them, our business is now more efficient and competitive than ever before.</p>
                                    <ul className="list-none mb-0 text-amber-400 mt-3 space-x-1">
                                        <li className="inline"><i className="mdi mdi-star"></i></li>
                                        <li className="inline"><i className="mdi mdi-star"></i></li>
                                        <li className="inline"><i className="mdi mdi-star"></i></li>
                                        <li className="inline"><i className="mdi mdi-star"></i></li>
                                        <li className="inline"><i className="mdi mdi-star"></i></li>
                                    </ul>
                                </div>

                                <div className="text-center mt-5">
                                    <img src='../images/index/client.jpg' className="size-14 rounded-full shadow-md mx-auto" alt="" />
                                    <h6 className="mt-2 font-semibold">Mr. Subhashis Pani</h6>
                                    <span className="text-white/50 text-slate-400 text-sm">Director, Power of Stocks Private Limited</span>
                                </div>
                            </div>
                        </div>
                    </div>
             </section> */}

            <section className="relative md:py-24 py-16 bg-indigo-600/5">
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Client Success Stories</h3>
                        <p className="text-slate-400 max-w-xl mx-auto">We pride ourselves on delivering exceptional results and building lasting partnerships.</p>
                    </div>

                    <Swiper
                                slidesPerView={1}
                                spaceBetween={0}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                pagination={{
                                clickable: true,
                                }}
                                breakpoints={{
                                '@0.00': {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                '@0.75': {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                '@1.00': {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                '@1.50': {
                                    slidesPerView: 3,
                                    spaceBetween: 10,
                                },
                                }}
                                modules={[Autoplay]}
                                className="mySwiper"
                            >
                        <SwiperSlide>
                                <div className="cursor-e-resize">
                                    <div className="content relative shadow dark:shadow-gray-800 m-2 p-6 bg-white dark:bg-slate-900 before:content-[''] before:absolute before:start-1/2 before:-bottom-[4px] before:box-border before:border-8 before:rotate-[45deg] before:border-t-transparent before:border-e-white dark:before:border-e-slate-900 before:border-b-white dark:before:border-b-slate-900 before:border-s-transparent before:shadow-testi dark:before:shadow-gray-700 before:origin-top-left">
                                        <i className="mdi mdi-format-quote-open mdi-48px text-indigo-600"></i>
                                        <p className="text-slate-400">" Their expertise and dedication are truly commendable. Thanks to them, our business is now more efficient and competitive than ever before.                     "</p>
                                        <ul className="list-none mb-0 text-amber-400 mt-3 space-x-1">
                                            <li className="inline"><i className="mdi mdi-star"></i></li>
                                            <li className="inline"><i className="mdi mdi-star"></i></li>
                                            <li className="inline"><i className="mdi mdi-star"></i></li>
                                            <li className="inline"><i className="mdi mdi-star"></i></li>
                                            <li className="inline"><i className="mdi mdi-star"></i></li>
                                        </ul>
                                    </div>

                                    <div className="text-center mt-5">
                                        <img src='images/index/subhas.jpg' className="size-14 rounded-full shadow-md mx-auto" alt="" />
                                        <h6 className="mt-2 font-semibold"> Subhashis Pani</h6>
                                        <span className="text-slate-400 text-sm">Director, Power of Stocks Private Limited </span>
                                    </div>
                                </div>
                        </SwiperSlide>

                        <SwiperSlide>
                                <div className="cursor-e-resize">
                                    <div className="content relative shadow dark:shadow-gray-800 m-2 p-6 bg-white dark:bg-slate-900 before:content-[''] before:absolute before:start-1/2 before:-bottom-[4px] before:box-border before:border-8 before:rotate-[45deg] before:border-t-transparent before:border-e-white dark:before:border-e-slate-900 before:border-b-white dark:before:border-b-slate-900 before:border-s-transparent before:shadow-testi dark:before:shadow-gray-700 before:origin-top-left">
                                        <i className="mdi mdi-format-quote-open mdi-48px text-indigo-600"></i>
                                        <p className="text-slate-400">" Their school management software helps us stay organized, save time, and provide a better educational experience for our students. Highly recommended! "</p>
                                        <ul className="list-none mb-0 text-amber-400 mt-3 space-x-1">
                                            <li className="inline"><i className="mdi mdi-star"></i></li>
                                            <li className="inline"><i className="mdi mdi-star"></i></li>
                                            <li className="inline"><i className="mdi mdi-star"></i></li>
                                            <li className="inline"><i className="mdi mdi-star"></i></li>
                                            <li className="inline"><i className="mdi mdi-star"></i></li>
                                        </ul>
                                    </div>

                                    <div className="text-center mt-5">
                                        <img src='images/index/user.png' className="size-14 rounded-full shadow-md mx-auto" alt="" />
                                        <h6 className="mt-2 font-semibold">Vivek Shaw</h6>
                                        <span className="text-slate-400 text-sm">Sai Valley World School </span>
                                    </div>
                                </div>
                        </SwiperSlide>

                        <SwiperSlide>
                                <div className="cursor-e-resize">
                                    <div className="content relative shadow dark:shadow-gray-800 m-2 p-6 bg-white dark:bg-slate-900 before:content-[''] before:absolute before:start-1/2 before:-bottom-[4px] before:box-border before:border-8 before:rotate-[45deg] before:border-t-transparent before:border-e-white dark:before:border-e-slate-900 before:border-b-white dark:before:border-b-slate-900 before:border-s-transparent before:shadow-testi dark:before:shadow-gray-700 before:origin-top-left">
                                        <i className="mdi mdi-format-quote-open mdi-48px text-indigo-600"></i>
                                        <p className="text-slate-400">"Their dedication to delivering high-quality results and their ability to adapt to our changing needs have made them an invaluable partner in our journey.                    "</p>
                                        <ul className="list-none mb-0 text-amber-400 mt-3 space-x-1">
                                            <li className="inline"><i className="mdi mdi-star"></i></li>
                                            <li className="inline"><i className="mdi mdi-star"></i></li>
                                            <li className="inline"><i className="mdi mdi-star"></i></li>
                                            <li className="inline"><i className="mdi mdi-star"></i></li>
                                            <li className="inline"><i className="mdi mdi-star"></i></li>
                                        </ul>
                                    </div>

                                    <div className="text-center mt-5">
                                        <img src='images/index/manoj.png' className="size-14 rounded-full shadow-md mx-auto" alt="" />
                                        <h6 className="mt-2 font-semibold">Manoj Mittal </h6>
                                        <span className="text-slate-400 text-sm">Mittal Commerce Classes </span>
                                    </div>
                                </div>
                        </SwiperSlide>

                        

                    </Swiper>
                </div>
            </section>

                

            {/* <div className='container pb-16 mt-16'>
                <div className="grid md:grid-cols-12 grid-cols-1 items-center">
                    <div className="md:col-span-6">
                        <h6 className="text-indigo-600 text-sm font-bold uppercase mb-2">Blogs</h6>
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Reads Our Latest <br /> News & Blog</h3>
                    </div>

                    <div className="md:col-span-6">
                        <p className="text-slate-400 max-w-xl">Explore a diverse range of topics designed to inform, inspire, and engage.</p>
                    </div>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 mt-8 gap-[30px]">
                    <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                        <img src='images/index/Video Encryption Watermark.jpg' alt="" />
                        <div className="content p-6">
                            <Link to="/blog-detail" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Video Encryption Watermark</Link>
                            <p className="text-slate-400 mt-3">Video Watermark is a multimedia security for copy protection from cameras, mobile phones,</p>

                            <div className="mt-4">
                                <Link to="" className="relative inline-flex items-center font-normal tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More</Link>
                            </div>
                        </div>
                    </div>

                    <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                        <img src='images/index/Video Encryption Software.jpg' alt="" />
                        <div className="content p-6">
                            <Link to="/blog-detail" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Video Encryption Software</Link>
                            <p className="text-slate-400 mt-3">EduKrypt video encryption software is a secure way to send video lectures or for video on demand service.</p>

                            <div className="mt-4">
                                <Link to="" className="relative inline-flex items-center font-normal tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More</Link>
                            </div>
                        </div>
                    </div>

                    <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                        <img src='images/index/Video Security Android App.jpg' alt="" />
                        <div className="content p-6">
                            <Link to="/blog-detail" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Video Security Android App</Link>
                            <p className="text-slate-400 mt-3">Edukrypt emerges with Video encryption android app which uses best encryption technique, i.e. 256</p>

                            <div className="mt-4">
                                <Link to="" className="relative inline-flex items-center font-normal tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

               <div className="container relative mt-16 mb-16">
               <div class="container relative md:mt-24 mt-16 ">
                <div class="grid grid-cols-1 text-center">
                    <h3 class="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Join Our Team</h3>
                    <p class="text-slate-400 max-w-xl mx-auto">Join our dynamic team and be part of a thriving environment where innovation meets collaboration.</p>
                    <div class="mt-6">
                        <a class="py-2 px-5 inline-flex font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-4" href="/career"> Explore careers</a>
                        </div>
                        </div>
                        </div>
                </div>
            {/* <section className='mb-24'>
            <Swiper
                                slidesPerView={1}
                                spaceBetween={10}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                pagination={{
                                clickable: true,
                                }}
                                breakpoints={{
                                '@0.00': {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                '@0.75': {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                '@1.00': {
                                    slidesPerView: 3,
                                    spaceBetween: 40,
                                },
                                '@1.50': {
                                    slidesPerView: 4,
                                    spaceBetween: 50,
                                },
                                }}
                                modules={[Autoplay]}
                                className="mySwiper"
                            >
                        <SwiperSlide>
                            <Link to="">
                            <div className="group relative rounded-md shadow dark:shadow-gray-800 overflow-hidden mx-2">
                                <div className="relative">
                                    <img src='images/index/react-developer.jpg' className="group-hover:rotate-3 group-hover:scale-110 duration-500 ease-in-out" alt="" />
                                    <div className="absolute inset-0 bg-gradient-to-t from-black"></div>
                                </div>

                                <div className="absolute bottom-6 start-6 end-6">
                                    <Link to="#" className="text-white/70 hover:text-white text-lg block font-semibold duration-500 ease-in-out"> ReactJS Developer</Link>
                                    
                                </div>
                            </div>
                            </Link>
                        </SwiperSlide>

                        <SwiperSlide>
                            <Link to="">
                            <div className="group relative rounded-md shadow dark:shadow-gray-800 overflow-hidden mx-2">
                                <div className="relative">
                                    <img src='images/index/Android Developer.avif' className="group-hover:rotate-3 group-hover:scale-110 duration-500 ease-in-out" alt="" />
                                    <div className="absolute inset-0 bg-gradient-to-t from-black"></div>
                                </div>

                                <div className="absolute bottom-6 start-6 end-6">
                                    <Link to="#" className="text-white/70 hover:text-white text-lg block font-semibold duration-500 ease-in-out">Android Developer</Link>
                                   
                                </div>
                            </div>
                            </Link>
                        </SwiperSlide>

                        <SwiperSlide>
                            <Link to="">
                            <div className="group relative rounded-md shadow dark:shadow-gray-800 overflow-hidden mx-2">
                                <div className="relative">
                                    <img src='images/index/Web-Designer.avif' className="group-hover:rotate-3 group-hover:scale-110 duration-500 ease-in-out" alt="" />
                                    <div className="absolute inset-0 bg-gradient-to-t from-black"></div>
                                </div>

                                <div className="absolute bottom-6 start-6 end-6">
                                    <Link to="#" className="text-white/70 hover:text-white text-lg block font-semibold duration-500 ease-in-out">Web Designer</Link>
                                </div>
                            </div>
                            </Link>
                        </SwiperSlide>

                        <SwiperSlide>
                            <Link to="">
                            <div className="group relative rounded-md shadow dark:shadow-gray-800 overflow-hidden mx-2">
                                <div className="relative">
                                    <img src='images/index/SEO-Executive.avif' className="group-hover:rotate-3 group-hover:scale-110 duration-500 ease-in-out" alt="" />
                                    <div className="absolute inset-0 bg-gradient-to-t from-black"></div>
                                </div>

                                <div className="absolute bottom-6 start-6 end-6">
                                    <Link to="#" className="text-white/70 hover:text-white text-lg block font-semibold duration-500 ease-in-out">SEO Executive</Link>
                                </div>
                            </div>
                            </Link>
                        </SwiperSlide>

                        <SwiperSlide>
                            <Link to="">
                            <div className="group relative rounded-md shadow dark:shadow-gray-800 overflow-hidden mx-2">
                                <div className="relative">
                                    <img src='images/index/Sales-Executive.jpg' className="group-hover:rotate-3 group-hover:scale-110 duration-500 ease-in-out" alt="" />
                                    <div className="absolute inset-0 bg-gradient-to-t from-black"></div>
                                </div>

                                <div className="absolute bottom-6 start-6 end-6">
                                    <Link to="#" className="text-white/70 hover:text-white text-lg block font-semibold duration-500 ease-in-out">Sales Executive (Greater Noida West)</Link>
                                </div>
                            </div>
                            </Link>
                        </SwiperSlide>

                        <SwiperSlide>
                            <Link to="">
                            <div className="group relative rounded-md shadow dark:shadow-gray-800 overflow-hidden mx-2">
                                <div className="relative">
                                    <img src='images/index/Sales-Executive.jpg' className="group-hover:rotate-3 group-hover:scale-110 duration-500 ease-in-out" alt="" />
                                    <div className="absolute inset-0 bg-gradient-to-t from-black"></div>
                                </div>

                                <div className="absolute bottom-6 start-6 end-6">
                                    <Link to="#" className="text-white/70 hover:text-white text-lg block font-semibold duration-500 ease-in-out">Sales Executive (Kota)</Link>
                                </div>
                            </div>
                            </Link>
                        </SwiperSlide>
                </Swiper>
                </section> */}
             
            
       


            {/* <Footer /> */}
            <JobFooter/>
           
            <CookieModal />
        </>
    )
}
