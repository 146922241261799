import React from 'react';
import { Link } from 'react-router-dom';

import Navbar from '../../component/Navbar/navbar';

import * as Icon from 'react-feather'
import {MdKeyboardArrowRight,FaArrowRight} from '../../assets/icons/icons'
import JobFooter from '../../component/Footer/jobFooter';


export default function HelpcenterSupport() {
    return (
        <>
            <Navbar />

            <section className="relative table w-full py-36 bg-[url('../../assets/images/helpcenter.jpg')] bg-center bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-black opacity-80"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="md:text-4xl text-3xl md:leading-normal tracking-wide leading-normal font-medium text-white">Submit Your Support Request</h3>

                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-flex space-x-1">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/index">Techwind</Link></li>
                        <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight className="text-xl"/></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/helpcenter-overview">Helpcenter</Link></li>
                        <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight className="text-xl"/></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">Support</li>
                    </ul>
                </div>
            </section>


           

            <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px] mx-auto text-center">
                        <div className="lg:col-start-3 lg:col-span-8 md:col-start-2 md:col-span-10">
                            <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 p-6">
                            <form>
                                    <div className="grid lg:grid-cols-12 lg:gap-6">
                                        <div className="lg:col-span-6 mb-5">
                                            <div className="text-start">
                                                <label htmlFor="name" className="font-semibold">Your Name:</label>
                                                <div className="form-icon relative mt-2">
                                                    <Icon.User className="size-4 absolute top-3 start-4"></Icon.User>
                                                    <input name="name" id="name" type="text" className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder="Name :" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="lg:col-span-6 mb-5">
                                            <div className="text-start">
                                                <label htmlFor="email" className="font-semibold">Your Email:</label>
                                                <div className="form-icon relative mt-2">
                                                    <Icon.Mail className="size-4 absolute top-3 start-4"></Icon.Mail>
                                                    <input name="email" id="email" type="email" className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder="Email :" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-1">
                                        <div className="mb-5">
                                            <div className="text-start">
                                                <label htmlFor="subject" className="font-semibold">Your Question:</label>
                                                <div className="form-icon relative mt-2">
                                                    <Icon.Book className="size-4 absolute top-3 start-4"></Icon.Book>
                                                    <input name="subject" id="subject" className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder="Subject :" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-5">
                                            <div className="text-start">
                                                <label htmlFor="comments" className="font-semibold">Your Comment:</label>
                                                <div className="form-icon relative mt-2">
                                                    <Icon.MessageCircle className="size-4 absolute top-3 start-4"></Icon.MessageCircle>
                                                    <textarea name="comments" id="comments" className="form-input ps-11 w-full py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder="Message :"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div  className="py-2 px-5 font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md justify-center flex items-center">Send Message</div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container relative md:mt-24 mt-16">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Let's Connect!</h3>

                        <p className="text-slate-400 max-w-xl mx-auto">Drop us a message using the form or reach out directly via email or phone. Our team is ready to assist you.</p>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 mt-6 gap-[30px]">
                        <div className="text-center px-6 mt-6" >
                            <div className="size-20 help_center_icon bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                                    <svg id="Layer_1" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m499.509 83.651-.006-.007-.012-.01a40.863 40.863 0 0 0 -28.6-11.583h-429.781a40.87 40.87 0 0 0 -28.367 11.36l-.014.013-.008.007-.005.005-.009.01a40.719 40.719 0 0 0 -12.707 29.712v285.685a41.136 41.136 0 0 0 41.11 41.11h429.779a41.135 41.135 0 0 0 41.111-41.11v-285.685a40.768 40.768 0 0 0 -12.491-29.507zm-170.6 172.073 162.318-150.58a21.813 21.813 0 0 1 1.508 8.014v285.685a21.8 21.8 0 0 1 -1.5 7.969zm141.98-164.417a21.924 21.924 0 0 1 6.025.849l-220.746 204.782-116.55-108.117-104.258-96.734a21.942 21.942 0 0 1 5.75-.78zm-450.133 315.506a21.837 21.837 0 0 1 -1.5-7.97v-285.685a21.808 21.808 0 0 1 1.6-8.251l105.662 98.033 56.732 52.627zm20.354 13.88a21.871 21.871 0 0 1 -6.07-.862l162.369-151.131 52.21 48.433a9.632 9.632 0 0 0 13.1 0l52.038-48.275 162.2 150.974a21.91 21.91 0 0 1 -6.071.861z"/></svg>
                            </div>

                            <div className="content mt-7">
                                <Link to="/" className="title h5 text-lg font-medium hover:text-indigo-600">Email</Link>
                                <p className='mt-3'><a href='mailto:connect@edukrypt.com'className='text-slate-400 mt-3'>connect@edukrypt.com</a></p>

                            </div>
                        </div>

                        <div className="text-center px-6 mt-6" >
                            <div className="size-20 help_center_icon bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                                <svg id="Layer_3" height="512" viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 3"><path d="m29.393 23.36c-.874-.733-6-3.979-6.852-3.83-.4.071-.706.412-1.525 1.389a11.687 11.687 0 0 1 -1.244 1.347 10.757 10.757 0 0 1 -2.374-.88 14.7 14.7 0 0 1 -6.784-6.786 10.757 10.757 0 0 1 -.88-2.374 11.687 11.687 0 0 1 1.347-1.244c.976-.819 1.318-1.123 1.389-1.525.149-.854-3.1-5.978-3.83-6.852-.306-.362-.584-.605-.94-.605-1.032 0-5.7 5.772-5.7 6.52 0 .061.1 6.07 7.689 13.791 7.721 7.589 13.73 7.689 13.791 7.689.748 0 6.52-4.668 6.52-5.7 0-.356-.243-.634-.607-.94z"/><path d="m23 15h2a8.009 8.009 0 0 0 -8-8v2a6.006 6.006 0 0 1 6 6z"/><path d="m28 15h2a13.015 13.015 0 0 0 -13-13v2a11.013 11.013 0 0 1 11 11z"/></svg>
                            </div>

                            <div className="content mt-7">
                                <Link to="/" className="title h5 text-lg font-medium hover:text-indigo-600">Contact</Link>
                                <p className='mt-3'><a href='call:8851286001'className='text-slate-400 mt-3'>8851286001</a></p>
                            </div>
                        </div>

                        <div className="text-center px-6 mt-6" >
                            <div className="size-20 help_center_icon bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                                <svg id="Layer_1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m256 88.669a74.165 74.165 0 1 0 74.165 74.165 74.249 74.249 0 0 0 -74.165-74.165zm0 124.331a50.165 50.165 0 1 1 50.165-50.165 50.222 50.222 0 0 1 -50.165 50.165zm115.271-165.437a163.464 163.464 0 0 0 -230.542 0c-29.683 29.685-44.282 68.265-42.218 111.571 1.787 37.49 15.817 77.919 40.573 116.917l106.785 168.216a12 12 0 0 0 20.262 0l106.785-168.216c24.756-39 38.786-79.427 40.573-116.917 2.064-43.306-12.535-81.886-42.218-111.571zm-18.617 215.625-96.654 152.256-96.654-152.256c-48.7-76.719-49.318-150.982-1.646-198.654a139.175 139.175 0 0 1 196.6 0c47.672 47.672 47.056 121.935-1.646 198.654zm-225.846 174.647c0 11.222 12.781 23.549 34.19 32.975 25.178 11.09 58.917 17.19 95.002 17.19s69.824-6.105 95-17.19c21.409-9.426 34.19-21.753 34.19-32.975 0-14.643-21.094-30.369-53.739-40.066a12 12 0 0 1 6.834-23.006c45.061 13.384 70.905 36.374 70.905 63.072 0 21.652-17.231 41.164-48.519 54.939-28.156 12.399-65.329 19.226-104.671 19.226s-76.515-6.827-104.673-19.226c-31.288-13.775-48.519-33.287-48.519-54.939 0-26.7 25.844-49.688 70.9-63.072a12 12 0 0 1 6.834 23.006c-32.642 9.697-53.734 25.423-53.734 40.066z"/></svg>
                            </div>

                            <div className="content mt-7">
                                <Link to="/" className="title h5 text-lg font-medium hover:text-indigo-600">Address</Link>
                                <p className="text-slate-400 mt-3">7th Floor, TS-01, Galaxy Blue Sapphire Plaza, Plot No. C-03, Sector-4, Greater Noida, Uttar Pradesh-201306</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <JobFooter/>

        </>
    )
}
